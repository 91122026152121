import {RootState} from '@src/store/store'
import {AppStateType} from "@src/store/ducks/app/reducer";

export const selectApp = (state: RootState): AppStateType => state.app

export const selectTourChoiseOpen = (state: RootState) => selectApp(state).isTourChoiseOpen
export const selectTourFormalisationOpen = (state: RootState) => selectApp(state).isTourFormalisationOpen

export const selectSuccessPageOpen = (state: RootState) => selectApp(state).isSuccessPage

export const selectIsMobileMenuActive = (state: RootState) => selectApp(state).IsMobileMenuActive
