import React from "react";
import style from '@src/pages/Tours/SuccessPage/SuccessPage.module.scss'
import success from "@src/images/suceessSvg.svg";
import successBg from "@src/images/success-bg.svg";
import {Button} from "@src/components/Button/Button";
import {Download} from "@src/components/AllSvg/Download";
import {NavLink} from "react-router-dom";
import {setSuccessPage, setTourChoiseOpen, setTourFormalisationOpen} from "@src/store/ducks/app/reducer";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "@src/store/store";
import {getHistoryAsinc, getPdfActAsinc, getPdfReportAsinc} from "@src/store/ducks/orders/thunks";
import {selectOrderId} from "@src/store/ducks/orders/selectors";
import {setPassengersQuantity, setStepsState} from "@src/store/ducks/orders/reducer";
import {selectUserInfo} from "@src/store/ducks/user/selectors";
import {getTripsResultDataAsinc} from "@src/store/ducks/trips/thunks";


export const SuccessPage = () => {
    const dispatch = useDispatch<AppDispatch>()
    const orderId = useSelector(selectOrderId)
    const user = useSelector(selectUserInfo)

    dispatch(setPassengersQuantity(''))
    dispatch( getTripsResultDataAsinc({}))
    dispatch(getHistoryAsinc(1, user.id))


    return (
        <div className={style.successPage}>
            <div className={style.wrapper}>
                <div className={style['wrapper-inner']}>
                    <div className={style.successImage}>
                        <img
                            src={success}
                            alt="success"
                        />
                    </div>
                    <div className={style.title}>
                        Ваша заявка успешно оформлена
                    </div>
{/*                    {user.roleId !== 3 &&
                        <div className={style.download}>
                            <Button onClickFunc={() => dispatch(getPdfActAsinc(orderId))} svgComponent={Download}
                                    className={style.downloadBtn}> Скачать счет</Button>
                            <Button onClickFunc={() => dispatch(getPdfReportAsinc(orderId))} svgComponent={Download}
                                    className={style.downloadBtn}> Скачать акт</Button>
                        </div>}*/}
                    <div className={style['return-container']}>
                        <NavLink
                            to="/tours"
                            onClick={() => {
                                dispatch(setTourFormalisationOpen(false));
                                dispatch(setSuccessPage(false));
                                dispatch(setTourChoiseOpen(true));
                            }}>
                            <Button onClickFunc={() => dispatch(setStepsState())} className={style.return}>Вернуться к
                                подбору туров</Button>
                        </NavLink>
                    </div>
                    <div className={style['bg-1']}>
                        <img
                            src={successBg}
                            alt="success"
                        />
                    </div>
                    <div className={style['bg-2']}>
                        <img
                            src={successBg}
                            alt="success"
                        />
                    </div>
                    <div className={style['bg-3']}>
                        <img
                            src={successBg}
                            alt="success"
                        />
                    </div>
                    <div className={style['bg-4']}>
                        <img
                            src={successBg}
                            alt="success"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
