import React from "react";


export const Download = () => {
return (
    <svg width="100%" height="100%" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.07918 7.33331C1.02701 7.71415 1 8.10373 1 8.49998C1 12.9182 4.35787 16.5 8.5 16.5C12.6422 16.5 16 12.9182 16 8.49998C16 8.10373 15.973 7.71415 15.9208 7.33331" stroke="#232323" strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M8.49935 9.83333V1.5M8.49935 9.83333C7.91585 9.83333 6.82562 8.17142 6.41602 7.75M8.49935 9.83333C9.08285 9.83333 10.1731 8.17142 10.5827 7.75" stroke="#232323" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)
}