import React, {FC} from "react";
import style from '@src/pages/Tours/TourFormalization/components/steps/Step5/Step5.module.scss'
import {Place} from "@src/components/AllSvg/Place";
import {useFormikContext} from "formik";
import {useSelector} from "react-redux";
import {selectRoomTypes} from "@src/store/ducks/orders/selectors";
import cn from "classnames";

type Step2Props = {
    data: {
        state: string,
        fio: string,
        fioLat: string,
        birthDate: string,
        passportExpiryDate: string,
        passportSeriesAndNumber: string,
        phoneNumber: string,
        roomType: string,
        hotel: string,
        gender: string,
        fromCity: string,
        citizenCategory: string,
        nationality: string,
    }
    index: number
    vehicleType: string
}


// TRAIN FLOW: add vehicleType
export const OrderElem: FC<Step2Props> = ({data, index, vehicleType}) => {
    const dataRoomTypesFromApi = useSelector(selectRoomTypes)

    const formik = useFormikContext();
    if (!formik) {
        return null; // Возвращаем null или другое значение, если контекст Formik не доступен
    }
    const {getFieldProps} = formik;



    const orderData = {
        fio: data.fio,
        fioLat: data.fioLat,
        birthDate: data.birthDate,
        passportExpiryDate: data.passportExpiryDate,
        passportSeriesAndNumber: data.passportSeriesAndNumber,
        phoneNumber: data.phoneNumber,
        roomType: data.roomType,
        hotel: data.hotel,
        gender: data.gender,
        fromCity: data.fromCity,
        citizenCategory: data.citizenCategory,
        nationality: data.nationality,
    }

    const roomTypeTitle = dataRoomTypesFromApi.find(item => item.id === +orderData.roomType);


    return (
        <div className={style['order-elem']}>
            <div className={style['order-elem-title']}>
                Турист №{index + 1} ({orderData.citizenCategory})
            </div>
            <div className={style['order-elem-list']}>
                <div className={style['order-elem-item']}>
                    <span>ФИО на русском</span>
                    <span>{orderData.fio}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>ФИО атиницей</span>
                    <span>{orderData.fioLat}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>Дата рождения</span>
                    <span>{orderData.birthDate}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>Серия и номер паспорта</span>
                    <span>{orderData.passportSeriesAndNumber}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>Дата окончания паспорта</span>
                    <span>{orderData.passportExpiryDate}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>Номер телефона туриста</span>
                    <span>{orderData.phoneNumber}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>Категория номера в отеле</span>
                    <span>{roomTypeTitle?.title}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>Категория гражданина (-ки)</span>
                    <span>{orderData.citizenCategory}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>Пол туриста</span>
                    <span>{orderData.gender}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>Гражданство</span>
                    <span>{orderData.nationality}</span>
                </div>
                <div className={style['order-elem-item']}>
                    <span>Отель</span>
                    <span>{orderData.hotel}</span>
                </div>
            </div>
            {/* TRAIN FLOW: hide if*/}
            <div className={cn(style['place-wrapper'], vehicleType !== 'bus' ? 'general--hidden': '')}>
                <div className={style.place}>
                    <span>{getFieldProps('data.selectedPlaces').value[index]}</span>
                    <Place/>
                </div>
                <span>Выбранное место</span>
            </div>
        </div>
    )
}
