import {userApi} from '@src/api/user-api'
import {AppDispatch} from '@src/store/store'
import {setIsAuth} from "@src/store/ducks/auth/reducer";
import {setAvailablePlaces, setUser} from "@src/store/ducks/user/reducer";
import {ApiResponse} from "@src/store/ducks/user/types";

export const fetchMe = (id:number) => async (dispatch: AppDispatch) => {
  try {
    const result:ApiResponse = await userApi.getMe(id)
    dispatch(setUser(result))
    dispatch(getAvailablePlacesAsinc(id))
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(setIsAuth(false))
    }
  } finally {
/*    dispatch(setIsInitialized())*/
  }
}

export const changeUser = (user:any, navigate: any) => async (dispatch: AppDispatch) => {
  try {
    const result = await userApi.changeMe(user)
    dispatch(setUser(result))
    navigate("/profile");
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(setIsAuth(false))
    }
  } finally {
    /*    dispatch(setIsInitialized())*/
  }
}

export const getAvailablePlacesAsinc = (id:any) => async (dispatch: AppDispatch) => {
  try {
    const result = await userApi.getAvailablePlaces(id)
    dispatch(setAvailablePlaces(result))
  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(setIsAuth(false))
    }
  } finally {
    /*    dispatch(setIsInitialized())*/
  }
}

export const changeImage = (formData:any) => async (dispatch: AppDispatch) => {
  try {
    const result = await userApi.changeImage(formData)
    dispatch(setUser(result))

  } catch (err: any) {
    if (err?.response?.status === 401) {
      dispatch(setIsAuth(false))
    }
  } finally {
    /*    dispatch(setIsInitialized())*/
  }
}


