import {api} from '@src/api/api'

export const OrdersApi = {
  getBusPlaces(id:number) {
    return api.get('/v1/trips/getBusPlaces', {trip_id:id})
  },
  getParams() {
    return api.get('/v1/orders/get/params')
  },
  getHistory(status:number | null, id:number|null) {
    return api.get('/v1/orders/get', {user_id:id, status:status})
  },
  postOrder(data:any) {
    return api.post('/v1/orders/create', data)
  },
  getPdfAct(id:number) {
    return api.get('/v1/orders/getPdfAct', {order_id:id})
  },
  getPdfReport(id:number) {
    return api.get('/v1/orders/getPdfReport', {order_id:id})
  },
}
