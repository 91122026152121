import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {TripsDataFilterType, TripsDataType, TripsResultType} from "@src/store/ducks/trips/types";

export const initialState = {
  tripsData: {
    countries: [],
    cities: [],
    tours: []
  } as TripsDataType,
  tripsDataFilter : {
    countryFilter: null,
    cityFilter: null,
    toursFilter: {
      countryFilterId: null,
      cityFilterId: null,
    },
  } as TripsDataFilterType,
  tripsResult: {
    hasMore: false,
    next: null,
    trips: [],
  } as TripsResultType,
}
const tripsSlice = createSlice({
  name: 'trips',
  initialState,
  reducers: {
    setTripsData(state , { payload }: PayloadAction<TripsDataType>) {
      state.tripsData = payload
    },
    filterTripsDataCountry(state , { payload }: PayloadAction<number | null | undefined>) {
      state.tripsDataFilter.countryFilter = payload
    },
    filterTripsDataCity(state , { payload }: PayloadAction<number | null | undefined>) {
      state.tripsDataFilter.cityFilter = payload
    },
    filterTripsDataToursCountry(state , { payload }: PayloadAction<number | null | undefined>) {
      state.tripsDataFilter.toursFilter.countryFilterId = payload
    },
    filterTripsDataToursCity(state , { payload }: PayloadAction<number | null | undefined>) {
      state.tripsDataFilter.toursFilter.cityFilterId = payload
    },
    setTripsResultData(state , { payload }: PayloadAction<TripsResultType>) {
      state.tripsResult = payload
    },
    setTripsResultArr(state , { payload }: PayloadAction<TripsResultType>) {
      payload.trips.forEach(newTrip => {
        if(!state.tripsResult.trips.some(trip => trip.id === newTrip.id)) {
          state.tripsResult.trips.push(newTrip);
        }
      });
      state.tripsResult.next = payload.next;
      state.tripsResult.hasMore = payload.hasMore;
      state.tripsResult.total = payload.total;
    }
  },
})
export type TripsStateType = typeof initialState
export const {
  setTripsData,
  filterTripsDataCountry,
  filterTripsDataCity,
  filterTripsDataToursCountry,
  filterTripsDataToursCity,
  setTripsResultData,
  setTripsResultArr
} = tripsSlice.actions
export const tripsReducer = tripsSlice.reducer
