import React, {FC} from "react";

import {Button} from "@src/components/Button/Button";
import style from '@src/pages/Tours/TourFormalization/components/tabs-step/TabsStep.module.scss'
import {selectSteps} from "@src/store/ducks/orders/selectors";
import {useSelector} from "react-redux";
import cn from "classnames";

type StepsProps = {
    // TRAIN FLOW: add vehicleType
    vehicleType: string,
    step: number
    setStep?: any;
    className?:string,
    openMobileSteps?:boolean
    setOpenMobileStep?:any
}

// TRAIN FLOW: add vehicleType
export const TabsStep: FC<StepsProps> = ({vehicleType, step, setStep, className,openMobileSteps, setOpenMobileStep}) => {
    const stepsState = useSelector(selectSteps)

    // TRAIN FLOW: choosing step btn depend of vehicle type
    let buttons = null;

    switch (vehicleType) {
        case 'plane':
        case 'train':
            buttons = (
                <>
                    <Button
                        disabled
                        arrow={window.matchMedia('(max-width: 475px)').matches && !openMobileSteps}
                        // onClickFunc={() => {setStep(4); window.matchMedia('(max-width: 475px)').matches && setOpenMobileStep(!openMobileSteps)}}
                        step={'3'}
                        className={'btn-tab-formalization hidden'}>
                        Выбор мест недоступен
                    </Button>
                    <Button disabled={stepsState.step4} arrow={window.matchMedia('(max-width: 475px)').matches && !openMobileSteps} onClickFunc={() => {setStep(4); window.matchMedia('(max-width: 475px)').matches && setOpenMobileStep(!openMobileSteps)}} step={'3'}
                            className={(stepsState.step4 ? 'btn-tab-formalization' : 'btn-tab-formalization btn-steps-open') + (step === 4 ? ' btn-steps-active' : '')}>Дополнительно
                        и оплата</Button>
                    <Button disabled={stepsState.step5} arrow={window.matchMedia('(max-width: 475px)').matches && !openMobileSteps} onClickFunc={() => {setStep(5); window.matchMedia('(max-width: 475px)').matches && setOpenMobileStep(!openMobileSteps)}} step={'4'}
                            className={(stepsState.step5 ? 'btn-tab-formalization' : 'btn-tab-formalization btn-steps-open') + (step === 5 ? ' btn-steps-active' : '')}>Итоговые
                        данные</Button>
                </>
            );
            break;
        default:
            // default: bus type
            buttons = (
                <>
                    <Button disabled={stepsState.step3} arrow={window.matchMedia('(max-width: 475px)').matches && !openMobileSteps} onClickFunc={() => {setStep(3); window.matchMedia('(max-width: 475px)').matches && setOpenMobileStep(!openMobileSteps)}} step={'3'}
                            className={(stepsState.step3 ? 'btn-tab-formalization' : 'btn-tab-formalization btn-steps-open') + (step === 3 ? ' btn-steps-active' : '')}>Выбор
                        места</Button>

                    <Button disabled={stepsState.step4} arrow={window.matchMedia('(max-width: 475px)').matches && !openMobileSteps} onClickFunc={() => {setStep(4); window.matchMedia('(max-width: 475px)').matches && setOpenMobileStep(!openMobileSteps)}} step={'4'}
                            className={(stepsState.step4 ? 'btn-tab-formalization' : 'btn-tab-formalization btn-steps-open') + (step === 4 ? ' btn-steps-active' : '')}>Дополнительно
                        и оплата</Button>
                    <Button disabled={stepsState.step5} arrow={window.matchMedia('(max-width: 475px)').matches && !openMobileSteps} onClickFunc={() => {setStep(5); window.matchMedia('(max-width: 475px)').matches && setOpenMobileStep(!openMobileSteps)}} step={'5'}
                            className={(stepsState.step5 ? 'btn-tab-formalization' : 'btn-tab-formalization btn-steps-open') + (step === 5 ? ' btn-steps-active' : '')}>Итоговые
                        данные</Button>
                </>
            );
            break;
    }

    return (
        <div className={style['tabs-step-wrapper']}>
            <div className={cn(style['tabs-step'], className)}>
                <Button disabled={stepsState.step1} arrow={window.matchMedia('(max-width: 475px)').matches && !openMobileSteps} onClickFunc={() => {setStep(1); window.matchMedia('(max-width: 475px)').matches && setOpenMobileStep(!openMobileSteps)}} step={'1'}
                        className={(stepsState.step1 ? 'btn-tab-formalization' : 'btn-tab-formalization btn-steps-open') + (step === 1 ? ' btn-steps-active' : '')}>
                    Общая информация
                </Button>
                <Button disabled={stepsState.step2} arrow={window.matchMedia('(max-width: 475px)').matches && !openMobileSteps} onClickFunc={() => {setStep(2);window.matchMedia('(max-width: 475px)').matches && setOpenMobileStep(!openMobileSteps)}} step={'2'}
                        className={(stepsState.step2 ? 'btn-tab-formalization' : 'btn-tab-formalization btn-steps-open') + (step === 2 ? ' btn-steps-active' : '')}>Данные
                    пассажиров</Button>
                {/*<Button disabled={stepsState.step3} arrow={window.matchMedia('(max-width: 475px)').matches && !openMobileSteps} onClickFunc={() => {setStep(3); window.matchMedia('(max-width: 475px)').matches && setOpenMobileStep(!openMobileSteps)}} step={'3'}*/}
                {/*        className={(stepsState.step3 ? 'btn-tab-formalization' : 'btn-tab-formalization btn-steps-open') + (step === 3 ? ' btn-steps-active' : '')}>Выбор*/}
                {/*    места</Button>*/}
                {buttons}
            </div>
        </div>
    )
}
