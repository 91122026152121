import {RootState} from '@src/store/store'
import {TripsStateType} from "@src/store/ducks/trips/reducer";


export const selectTrips = (state: RootState): TripsStateType => state.trips

export const selectTripsDataCountry = (state: RootState) => selectTrips(state).tripsData.countries
export const selectTripsDataCities = (state: RootState) => selectTrips(state).tripsData.cities
export const selectTripsDataTours = (state: RootState) => selectTrips(state).tripsData.tours

export const selectTripsDataFilter = (state: RootState) => selectTrips(state).tripsDataFilter

export const selectTripsResultArr = (state: RootState) => selectTrips(state).tripsResult.trips

export const selectTripsResultAmount = (state: RootState) => selectTrips(state).tripsResult.total

export const selectTripsResultNextPage = (state: RootState) => selectTrips(state).tripsResult.next
export const selectTripsResultHasMore = (state: RootState) => selectTrips(state).tripsResult.hasMore

