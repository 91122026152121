import React, {FC, useEffect, useRef} from "react";
import style from "@src/pages/Tours/TourChoise/components/TourRoute/TourRoute.module.scss";
import burgerClose from "@src/images/burger-close.svg";


type TableElemProps = {
    routes: any
    setShowRoute: React.Dispatch<React.SetStateAction<boolean>>;
    showRoute:boolean

};

export const TourRoute: FC<TableElemProps> = ({routes, setShowRoute}) => {
    return (
        <div className={style.tourRoute} onClick={() => setShowRoute(false)}>
            <div className={style['tourRoute-inner']}>
                <div className={style['tourRoute-body']}>
                    <div className={style['tourRoute-body-inner']} onClick={(e) => e.stopPropagation()}>
                        <div className={style.burger}>
                            <div onClick={() => setShowRoute(false)} className={style['burger-close']}>
                                <img
                                    src={burgerClose}
                                    alt="burger-close"
                                />
                            </div>
                        </div>
                        <div className={style.title}>
                            Маршрут тура
                        </div>
                        <div className={style['tourRoute-list']}>
                            {routes?.map((item: string[], index: number) => (
                                <div key={index} className={style['tourRoute-item']}>
                                    <div className={style.decor}>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <span>{item[1]}</span> <span>{item[0] === '1'? '': '(есть посадка)'}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
