import React, {useMemo} from "react";
import {Form, Formik} from "formik";
import * as Yup from 'yup'
import {FieldFormik} from '@src/components/common/FieldFormik/FieldFormik'
import {Button} from "@src/components/Button/Button";
import {validateForm} from '@src/messages/validateForm'

import style from '@src/pages/NewPassword/NewPassword.module.scss'
import {AppDispatch} from "@src/store/store";
import {useDispatch, useSelector} from "react-redux";
import {selectUserEmail} from "@src/store/ducks/auth/selectors";
import {changePasswordNewAsinc} from "@src/store/ducks/auth/thunks";
import {NavLink, useNavigate} from "react-router-dom";
import {selectCode} from "@src/store/ducks/user/selectors";

const validationSchema = Yup.object().shape({
    password: Yup.string().required(validateForm.required)
        .notOneOf([Yup.ref('oldPassword')], 'Новый пароль идентичен старому')
        .min(8, 'Минимальная длина строки 8 символов').max(8, 'Длина строки должна быть 8 символов'),
    confirmPassword: Yup.string().required(validateForm.required)
        .oneOf([Yup.ref('password')/*, null*/], 'Пароли не совпадают')
        .min(8, 'Минимальная длина строки 8 символов').max(8, 'Длина строки должна быть 8 символов'),
})

export const NewPasswordForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>()
    const initialValues = useMemo(() => ({password: '', confirmPassword: ''}), [])
    const code = useSelector(selectCode)
    const emailUser = useSelector(selectUserEmail)

  return (
      <Formik
          initialValues={initialValues}
          onSubmit={(value) => {
              dispatch(changePasswordNewAsinc(+code,emailUser, value.password, value.confirmPassword, navigate))
          }}
          validationSchema={validationSchema}
      >
          {({resetForm}) => (
              <Form className={style['changePassword-container']}>
                  <div className={style['changePassword']}>
                      <div className={style['form-container']}>
                          <FieldFormik
                              name="password"
                              placeholder="Минимум 8 символов"
                              label="Введите новый пароль"
                              typeInput='input-password'
                          />
                          <FieldFormik
                              name="confirmPassword"
                              placeholder="Минимум 8 символов"
                              label="Повторите новый пароль"
                              typeInput='input-password'
                          />
                      </div>
                      <div className={style['btnBlock']}>
                          <Button htmlType={'submit'} className={'btn-save btn-width'}>Сохранить данные</Button>

                          <NavLink
                              to="/login"
                              className={`${style.forgotBtn}`}
                          >
                              Вернуться назад
                          </NavLink>
                      </div>
                  </div>
                  {/*                    {backError && isString(backError) && <div className={style.error}>{backError}</div>}*/}
              </Form>
          )}
      </Formik>
  )
}
