import {FC, useMemo} from "react";
import {Form, Formik} from "formik";
import * as Yup from 'yup'

import {NavLink, useNavigate} from "react-router-dom";
import cn from 'classnames'
import {FieldFormik} from '@src/components/common/FieldFormik/FieldFormik'
import {Button} from "@src/components/Button/Button";
import {validateForm} from '@src/messages/validateForm'
import {emailRegexp} from '@src/lib/regex'
import style from '@src/pages/RecoveryPassword/RecoveryPassword.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {selectBackError} from "@src/store/ducks/auth/selectors";
import {AppDispatch} from "@src/store/store";
import {checkEmailAsinc} from "@src/store/ducks/auth/thunks";
import {setUserEmail} from "@src/store/ducks/auth/reducer";

type LoginFormProps = {}

export const RecoveryForm: FC<LoginFormProps> = () => {
    const backError = useSelector(selectBackError)
    const dispatch = useDispatch<AppDispatch>()
    let navigate = useNavigate();

    const validationSchema = useMemo(() => (
        Yup.object().shape({
            emailUser: Yup.string().matches(emailRegexp, validateForm.email).required(validateForm.required),
        })
    ), [])

    const initialValues = useMemo(() => ({
        emailUser: '',
    }), [])

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={({emailUser}) => {
                dispatch(setUserEmail(emailUser))
                dispatch(checkEmailAsinc(emailUser, navigate))
            }}
            validationSchema={validationSchema}
        >
            {() => (
                <Form>
                    <div>
                        <FieldFormik
                            name="emailUser"
                            placeholder='mymail@gmail.com'
                            label='Электронная почта'
                            typeInput='input-usual'
                        />
                    </div>
                    {backError && String(backError) && (
                        <div className={style.error}>
                            {backError}
                        </div>
                    )}
                    <div className={style.btnBlock}>
                        <Button
                            htmlType="submit"
                            type="secondary"
                            className={cn('btn', 'btn-second')}
                        >
                            Восстановить пароль
                        </Button>
                        <NavLink
                            to="/login"
                            className={`${style.forgotBtn}`}
                        >
                            Вернуться назад
                        </NavLink>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
