import spin from '@src/images/spin.gif'
import style from '@src/components/LoadMore/LoadMore.module.scss'

export const LoadMore = () => {

    return (
        <div className={style['loadmore']}>
            <div className={style.spinner}>
                <img src={spin} alt={'spinner'}/>
            </div>
            <div className={style.text}>
                Загрузка туров
            </div>
        </div>
    )
}
