import cn from 'classnames'
import recoveryBg from '@src/images/recovery-bg.jpg'
import {TitleLogin} from "@src/components/TitleLogin/TitleLogin";
import style from '@src/pages/ConfirmPassword/ConfirmPassword.module.scss'
import React from "react";
import {ConfirmForm} from "@src/pages/ConfirmPassword/ConfirmForm";


export const ConfirmPassword = () => {
    return (
        <div className={cn(style.confirm)} >
            <div className={cn(style['confirm-left'])}>
                <img
                    src={recoveryBg}
                    alt="recoveryBg"
                />
            </div>
            <div className={cn(style['confirm-right'])}>
                <div className={cn(style['confirm-right-body'])}>
                    <TitleLogin title={'Введите код'} className={style.titleLogin}/>
                    <ConfirmForm/>
                </div>
            </div>
        </div>
    )
}
