import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {userReducer} from '@src/store/ducks/user/reducer'
import {authReducer} from '@src/store/ducks/auth/reducer'
import {ordersReducer} from "@src/store/ducks/orders/reducer";
import {tripsReducer} from "@src/store/ducks/trips/reducer";
import {appReducer} from "@src/store/ducks/app/reducer";

const combinedReducer = combineReducers({
  user: userReducer,
  isAuth: authReducer,
  orders:ordersReducer,
  trips: tripsReducer,
  app: appReducer
})

const rootReducer = (state: any, action: any) => {
  let tmpState = state
  if (action.type === 'auth/logoutAC') {
    tmpState = undefined
  }
  return combinedReducer(tmpState, action)
}

export const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
