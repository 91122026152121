import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export const initialState = {
    isTourChoiseOpen: true,
    isTourFormalisationOpen: false,
    isSuccessPage: false,
    IsMobileMenuActive:false
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setTourChoiseOpen(state, {payload}: PayloadAction<boolean>) {
            state.isTourChoiseOpen = payload
        },
        setTourFormalisationOpen(state, {payload}: PayloadAction<boolean>) {
            state.isTourFormalisationOpen = payload
        },
        setSuccessPage(state, {payload}: PayloadAction<boolean>) {
            state.isSuccessPage = payload
        },
        setMobileMenuActive(state, {payload}: PayloadAction<boolean>) {
            state.IsMobileMenuActive = payload
        }
    },
})

export type AppStateType = typeof initialState

export const {
    setTourChoiseOpen,
    setTourFormalisationOpen,
    setSuccessPage,
    setMobileMenuActive
} = appSlice.actions
export const appReducer = appSlice.reducer
