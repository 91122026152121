import {TourChoise} from "@src/pages/Tours/TourChoise/TourChoise";
import {TourFormalization} from "@src/pages/Tours/TourFormalization/TourFormalization";
import {SuccessPage} from "@src/pages/Tours/SuccessPage/SuccessPage";
import style from '@src/pages/Tours/Tours.module.scss'
import {useSelector} from "react-redux";
import {selectSuccessPageOpen, selectTourChoiseOpen, selectTourFormalisationOpen} from "@src/store/ducks/app/selectors";


export const Tours = () => {
    const showTourChoise = useSelector(selectTourChoiseOpen)
    const showTourFormalisationOpen = useSelector(selectTourFormalisationOpen)
    const showSuccessPage = useSelector(selectSuccessPageOpen)
    return (
        <div className={style.tours}>
            {showTourChoise && <TourChoise/>}
            {showTourFormalisationOpen && <TourFormalization/>}
            {showSuccessPage && <SuccessPage/>}
        </div>
    )
}
