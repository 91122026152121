import {TripsApi} from "@src/api/trips-api";
import {AppDispatch} from '@src/store/store'
import {setTripsData, setTripsResultArr, setTripsResultData} from "@src/store/ducks/trips/reducer";

export const getTripsDataAsinc = () => async (dispatch: AppDispatch) => {
  try {
    const result = await TripsApi.getTripsData()
    dispatch(setTripsData(result))
  } catch (err: any) {
    if (err?.response?.status === 401) {
    }
  } finally {
/*    dispatch(setIsInitialized())*/
  }
}

export const getTripsResultDataAsinc = (data = {}) => async (dispatch: AppDispatch) => {
  try {
    const result = await TripsApi.getTripsResult(data)
    dispatch(setTripsResultData(result))
  } catch (err: any) {
    if (err?.response?.status === 401) {
    }
  } finally {
  }
}

export const getTripsResultDataNextPage = (data = {}) => async (dispatch: AppDispatch) => {
  try {
    const result = await TripsApi.getTripsResult(data)
    dispatch(setTripsResultArr(result))
  } catch (err: any) {
    if (err?.response?.status === 401) {
    }
  } finally {
    /*    dispatch(setIsInitialized())*/
  }
}

