import {Title} from "@src/components/Title/Title";
import {HistorySvg} from "@src/components/AllSvg/HistorySvg";
import {Button} from "@src/components/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {selectHistoryOrdersDate, selectOrderStatuses} from "@src/store/ducks/orders/selectors";
import {getHistoryAsinc} from "@src/store/ducks/orders/thunks";
import style from "@src/pages/History/History.module.scss";
import React, {useState} from "react";
import {AppDispatch} from "@src/store/store";
import {TableHistoryElem} from "@src/pages/History/TableHistoryElem/TableHistoryElem";
import {OrdersHistoryElemType} from "@src/store/ducks/orders/types";
import cn from "classnames";
import {NavLink} from "react-router-dom";
import {setSuccessPage, setTourChoiseOpen, setTourFormalisationOpen} from "@src/store/ducks/app/reducer";
import logo from "@src/images/logo.svg";
import filter from "@src/images/filter-history.svg"
import burgerClose from "@src/images/burger-close.svg";
import {selectUserInfo} from "@src/store/ducks/user/selectors";


export const History = () => {
    const dispatch = useDispatch<AppDispatch>()
    const orderStatuses = useSelector(selectOrderStatuses)
    const [activeButton, setActiveButton] = useState(1);
    const historyData = useSelector(selectHistoryOrdersDate)
    const [openMobileTabs, setOpenMobileTabs] = useState(false)
    const user = useSelector(selectUserInfo)
    const [mobileTitle, setMobileTitle] = useState("Все")

    const handleClick = (buttonId: number) => {
        setActiveButton(buttonId);
    };

    return (
        <div className={style['history-wrapper']}>
            <Title title={'История'} svgComponent={HistorySvg}></Title>
            <div className={style.filter} onClick={() => setOpenMobileTabs(!openMobileTabs)}>
                <img src={filter} alt={filter}/>
                {mobileTitle}
            </div>
            <div className={cn(style['tabsStep-mobile'], openMobileTabs ? style['steps-mobile-active'] : '')}>
                <div className="container">
                    <div className={style['mobile-steps-inner']}>
                        <div className={style['steps-mb-logo']}>
                            <NavLink
                                to="/tours"
                                onClick={() => {
                                    dispatch(setTourFormalisationOpen(false));
                                    dispatch(setSuccessPage(false));
                                    dispatch(setTourChoiseOpen(true));
                                }}>
                                <div className={style.logo}>
                                    <img
                                        src={logo}
                                        alt="logo"
                                    />
                                </div>
                            </NavLink>
                        </div>
                        <div className={style.burger}>
                            <div onClick={() => setOpenMobileTabs(false)} className={style['burger-close']}>
                                <img
                                    src={burgerClose}
                                    alt="burger-close"
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style['tabsStep-mobile-title']}>Фильтрация заказов</div>
                    <div className={style.tabs}>
                        {orderStatuses && Object.keys(orderStatuses).map((key) => (
                            <Button key={key} onClickFunc={() => {
                                dispatch(getHistoryAsinc(+key, user.id));
/*                                dispatch(getHistoryAsinc(+key, 1));*/
                                handleClick(+key)
                                setMobileTitle(orderStatuses[key])
                                setOpenMobileTabs(false)
                            }} className={activeButton === +key ? style.btnActive : style.btnCustom}>
                                {orderStatuses[key]}
                            </Button>
                        ))}
                    </div>
                </div>
            </div>
            <div className={style['tabs-wrapper']}>
                <div className={style.tabs}>
                    {orderStatuses && Object.keys(orderStatuses).map((key) => (
                        <Button key={key} onClickFunc={() => {
                            dispatch(getHistoryAsinc(+key, user.id));
                            handleClick(+key);
                            setMobileTitle(orderStatuses[key])
                        }} className={activeButton === +key ? style.btnActive : style.btnCustom}>
                            {orderStatuses[key]}
                        </Button>
                    ))}
                </div>
            </div>
            <div className={style.tableHistory}>
                {(historyData && historyData.length !== 0)? historyData.map((item: OrdersHistoryElemType) => (
                    <TableHistoryElem key={item.id} item={item}/>)) : <span>Нет результатов</span>}
            </div>
        </div>
    )
}


