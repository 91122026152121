import {FC, useMemo} from "react";
import {Form, Formik} from "formik";
import * as Yup from 'yup'

import cn from 'classnames'
import {FieldFormik} from '@src/components/common/FieldFormik/FieldFormik'
import {Button} from "@src/components/Button/Button";
import {validateForm} from '@src/messages/validateForm'

import style from '@src/pages/ConfirmPassword/ConfirmPassword.module.scss'
import {AppDispatch} from "@src/store/store";
import {useDispatch, useSelector} from "react-redux";
import {selectBackError, selectUserEmail} from "@src/store/ducks/auth/selectors";
import {verifyCodeAsinc} from "@src/store/ducks/auth/thunks";
import {NavLink, useNavigate} from "react-router-dom";
import {setCode} from "@src/store/ducks/user/reducer";

type LoginFormProps = {}

export const ConfirmForm: FC<LoginFormProps> = () => {
    const dispatch = useDispatch<AppDispatch>()
    const emailUser = useSelector(selectUserEmail)
    const backError = useSelector(selectBackError)
    let navigate = useNavigate();

    const validationSchema = useMemo(() => (
        Yup.object().shape({
            code: Yup.string().min(4, 'Состоит из 8 символов')
                .max(4, 'Состоит из 8 символов').required(validateForm.required),
        })
    ), [])

    const initialValues = useMemo(() => ({
        code: '',
    }), [])

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={({code}) => {
                dispatch(setCode(code))
                dispatch(verifyCodeAsinc(emailUser, code, navigate))
            }}
            validationSchema={validationSchema}
        >
            {() => (
                <Form>
                    <div>
                        <FieldFormik
                            name="code"
                            placeholder='0000'
                            label='Код отправленный в письме'
                            typeInput='input-usual'
                        />
                    </div>
                    <div className={style.btnBlock}>
                        <Button
                            htmlType="submit"
                            type="secondary"
                            className={cn('btn', 'btn-second')}
                        >
                            Отправить
                        </Button>
                        <NavLink
                            to="/login"
                            className={`${style.forgotBtn}`}
                        >
                            Вернуться назад
                        </NavLink>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
