import {api} from '@src/api/api'

type loginResponse = {
    accessToken: string,
    expiresIn: number,
    tokenType: string,
    userId: number
}


export const authApi = {
    login(username: string, password: string) {
        return api.post<loginResponse>('/v1/auth/login', {email: username, password: password})
    },
    logout() {
        return api.post('/v1/auth/logout')
    },
    checkEmail(email: string) {
        return api.post('/v1/auth/verifications/checkEmail', {email: email})
    },
    sendCode(email: string) {
        return api.post('/v1/auth/verifications/sendCode', {email: email, type: 'registration'})
    },

    verifyCode(data: { email: string, code: number, type: string }) {
        return api.post('/v1/auth/verifications/verifyCode', data)
    },

    changePassword(email: string, password: string, newPassword: string) {
        return api.post('/v1/profile/password', {
            email: email,
            password: password,
            password_confirmation: newPassword
        })
    },
    changePasswordNew(code : number, email: string, password: string, newPassword: string) {
        return api.post('/v1/profile/password', {
            email: email,
            password: password,
            password_confirmation: newPassword,
            code: code
        })
    },
}
