import {FC, ReactElement} from 'react'
import {Header} from "@src/components/Header/Header";
import {HeaderMobile} from "@src/components/HeaderMobile/HeaderMobile";

type PrivateRouteProps = {
    Component: ReactElement
}

export const PrivateRoute: FC<PrivateRouteProps> = ({Component}) => {

    return (
        <div className={'privateRoute'}>
            <Header/>
            <HeaderMobile/>
            <main>
                <div className={'container'}>
                    {Component}
                </div>
            </main>
        </div>
    )
}
