import {useCallback, useEffect, useState} from 'react';
import {useFormikContext} from 'formik';
import {useSelector} from "react-redux";
import {selectCurrentChoosenTourData} from "@src/store/ducks/orders/selectors";
import {selectUserInfo} from "@src/store/ducks/user/selectors";
import moment from "moment/moment";


export const useCostCalculation = (vehicleType: string) => {
    const { getFieldProps, setFieldValue } = useFormikContext();
    const user = useSelector(selectUserInfo)
    const dataTourists = getFieldProps('data.tourists');
    const chosePlace = getFieldProps('data.useFreePlaces');
    const currentChoosenTourData = useSelector(selectCurrentChoosenTourData)
    const finalFirstPartPriceByn = getFieldProps('data.finalFirstPartPriceByn').value


    useEffect(() => {
        let finalPriceByn = null;
        let finalPriceUsd = null;

        let costForPlace = 0;
        let resultingTrainKidsDiscount = 0;

        if(vehicleType === 'bus') {
            /* Суммарная цена за выбор места*/
            costForPlace = (!chosePlace.value ? dataTourists.value.length * Number(currentChoosenTourData.tour.commissionAmountPlace) : 0);
        }
        // /* Суммарная цена за выбор места*/
        // let costForPlace = (!chosePlace.value ? dataTourists.value.length * Number(currentChoosenTourData.tour.commissionAmountPlace) : 0);

        /* Суммарная комиссия с партнера*/
        let costForComission = (user.roleId === 2 ? +currentChoosenTourData.tour.commissionAmountPartner * dataTourists.value.length : 0)

        /* Стоимость выбора трехместных номеров*/
        let costForRoomTypeTriple = dataTourists.value.reduce((accumulator:any, item:any) => {
            if(item.roomType === currentChoosenTourData.tripleRoomTypeAmount.id) {
                return accumulator + Number(currentChoosenTourData.tripleRoomTypeAmount.amount);
            } else {
                return accumulator
            }
        }, 0);

        /* Стоимость выбора одноместных номеров*/
        let costForRoomTypeSingle = dataTourists.value.reduce((accumulator:any, item:any) => {
            if(item.roomType === currentChoosenTourData.singleRoomTypeAmount.id) {
                return accumulator + Number(currentChoosenTourData.singleRoomTypeAmount.amount);
            } else {
                return accumulator
            }
        }, 0);

        // console.log('dataTourists:',dataTourists)
        // console.log('dataTourists.value:',dataTourists.value)
        // console.log('currentChoosenTourData:',currentChoosenTourData)
        // console.log('currentChoosenTourData.additionalPrice:',currentChoosenTourData.additionalPrice)

        if(vehicleType !== 'bus') {
            const currentDate = moment();
            const singleDiscountAmount = Number(currentChoosenTourData.childTrainPrice) ?? 0
            // console.log("if(vehicleType === 'train') {")
            /*Скидка детям младше 10 лет из рублевой части*/
            resultingTrainKidsDiscount = dataTourists.value.reduce((accumulator:number, item:any) => {
                // console.log("item:", item)
                // console.log("item.birthDate:", item.birthDate)

                const birthDate = moment(item.birthDate, "DD.MM.YYYY");
                const diffInYears = currentDate.diff(birthDate, 'years');
                // TODO?: add discount type: percentage or statistical value
                // console.log("accumulator:", accumulator);
                return diffInYears < 10 ? accumulator += singleDiscountAmount : accumulator;
            }, 0);
        }
            // console.log("currentChoosenTourData.additionalPrice:", currentChoosenTourData.additionalPrice)
            // console.log("trainKidsDiscount:", resultingTrainKidsDiscount)

        finalPriceByn = +currentChoosenTourData.additionalPrice * dataTourists.value.length - costForComission  + costForPlace - resultingTrainKidsDiscount

        /* Финальная стоимость в долларах*/
        finalPriceUsd = +currentChoosenTourData.price * dataTourists.value.length + costForRoomTypeSingle - costForRoomTypeTriple

        const finalPrizeInitial = getFieldProps('data.finalPriceByn')

        if( finalPrizeInitial.value !== String(finalPriceByn)) {
            setFieldValue('data.finalPriceByn', String(finalPriceByn));
            setFieldValue('data.finalPriceUsd', String(finalPriceUsd));
            setFieldValue('data.finalFirstPartPriceByn', String(finalPriceByn));
        }
    },[])


    useEffect(() => {
        let finalSecondPartPriceByn = null;

        /* Сумма первого взноса рублях*/
        finalSecondPartPriceByn = Number(getFieldProps('data.finalPriceByn').value) - Number(getFieldProps('data.finalFirstPartPriceByn').value)

        /* Финальная стоимость в долларах*/
        setFieldValue('data.finalSecondPartPriceByn', String(finalSecondPartPriceByn));
    }, [finalFirstPartPriceByn]);
}