import {RootState} from '@src/store/store'
import {AuthStateType, initialState} from './reducer'

export const selectAuth = (state: RootState):AuthStateType => state.isAuth || initialState

export const selectIsIni = (state: RootState): boolean => selectAuth(state).isLoading
export const selectIsAuth = (state: RootState): boolean => selectAuth(state).isAuth
export const selectUserEmail = (state: RootState) => selectAuth(state).userData.login

export const selectBackError = (state: RootState) => selectAuth(state).backError
