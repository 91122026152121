import React from "react";


export const ProfileSvg = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="profile (1) 1">
                <path id="Vector"
                      d="M12 15.75C15.1016 15.75 17.625 12.5539 17.625 8.625C17.625 4.69613 15.1016 1.5 12 1.5C8.89838 1.5 6.375 4.69613 6.375 8.625C6.375 12.5539 8.89838 15.75 12 15.75ZM12 3C14.2361 3 16.125 5.57588 16.125 8.625C16.125 11.6741 14.2361 14.25 12 14.25C9.76388 14.25 7.875 11.6741 7.875 8.625C7.875 5.57588 9.76388 3 12 3Z"
                      fill="#0062AA"/>
                <path id="Vector_2"
                      d="M1.53841 17.0126L0.0384105 21.5126C0.000881276 21.6254 -0.0093378 21.7454 0.0085944 21.8629C0.0265266 21.9803 0.0720974 22.0919 0.141556 22.1883C0.211015 22.2847 0.302375 22.3632 0.408118 22.4174C0.513861 22.4716 0.630963 22.4999 0.749786 22.5H23.2498C23.4909 22.5 23.7174 22.3841 23.858 22.1884C23.9276 22.092 23.9733 21.9805 23.9912 21.863C24.0091 21.7455 23.9988 21.6254 23.9612 21.5126L22.4612 17.0126C22.4225 16.897 22.3563 16.7925 22.2682 16.7082C22.1802 16.6239 22.0729 16.5624 21.9557 16.5289L16.7057 15.0289C16.5253 14.977 16.3319 14.9949 16.1642 15.0791L11.9998 17.1615L7.83541 15.0791C7.66759 14.9951 7.47433 14.9771 7.29391 15.0289L2.04391 16.5289C1.92668 16.5624 1.81938 16.6239 1.73129 16.7082C1.64321 16.7925 1.57701 16.897 1.53841 17.0126ZM7.42554 16.5514L11.6645 18.6709C11.7687 18.7228 11.8835 18.7499 12 18.7499C12.1164 18.7499 12.2312 18.7228 12.3354 18.6709L16.5744 16.5514L21.1637 17.8624L22.2095 21H1.79004L2.83591 17.8624L7.42554 16.5514Z"
                      fill="#0062AA"/>
            </g>
        </svg>
    )
}