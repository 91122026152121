import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ApiResponse, UserAllType, UserType} from "@src/store/ducks/user/types";

export const initialState = {
  user: {} as UserType,
  availablePlaces : null,
  code:'',
} as UserAllType
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state , { payload }: PayloadAction<ApiResponse>) {
      state.user = payload.user
    },
    setAvailablePlaces(state , { payload }: PayloadAction<any>) {
      state.availablePlaces = payload
    },
    setCode(state , { payload }: PayloadAction<string>) {
      state.code = payload
    },
  },
})
export type UserStateType = typeof initialState
export const {
  setUser,
  setAvailablePlaces,
  setCode

} = userSlice.actions
export const userReducer = userSlice.reducer
