import cn from 'classnames'
import recoveryBg from '@src/images/recovery-bg.jpg'
import {TitleLogin} from "@src/components/TitleLogin/TitleLogin";
import {RecoveryForm} from "@src/pages/RecoveryPassword/RecoveryForm";
import style from '@src/pages/RecoveryPassword/RecoveryPassword.module.scss'
import React from "react";


export const RecoveryPassword = () => {
    return (
        <div className={cn(style.recovery)} >
            <div className={cn(style['recovery-left'])}>
                <img
                    src={recoveryBg}
                    alt="recoveryBg"
                />
            </div>
            <div className={cn(style['recovery-right'])}>
                <div className={cn(style['recovery-right-body'])}>
                    <TitleLogin title={'Восстановление пароля'} className={style.titleLogin}/>
                    <RecoveryForm/>
                </div>
            </div>
        </div>
    )
}
