import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from 'react-router-dom'
import {App} from '@src/App'
import '@src/style/index.scss'
import {Provider} from "react-redux";
import {store} from "@src/store/store";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <BrowserRouter>
        <Provider store={store}>
        <App/>
        </Provider>
    </BrowserRouter>
);
