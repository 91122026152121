import {Button} from "@src/components/Button/Button";
import React, {FC, useCallback, useEffect} from "react";
import {FieldFormik} from "@src/components/common/FieldFormik/FieldFormik";
import {SelectCustom} from "@src/components/common/Select/SelectCustom";
import moment from "moment";
import {selectCurrentChoosenTourData, selectSourceRegirstration} from "@src/store/ducks/orders/selectors";
import {useDispatch, useSelector} from "react-redux";

import style from '@src/pages/Tours/TourFormalization/components/steps/Step4/Step4.module.scss'
import {TextAreaFormik} from "@src/components/common/TextAreaFormik/TextAreaFormik";
import {AppDispatch} from "@src/store/store";
import {setStepsState5} from "@src/store/ducks/orders/reducer";
import {useCostCalculation} from "@src/customFunctions/ СostСalculation";
import {useFormikContext} from "formik";
import * as Yup from "yup";
import {validateForm} from "@src/messages/validateForm";
import {selectUserInfo} from "@src/store/ducks/user/selectors";


type Step4Props = {
    step?: any
    setStep?: any
    vehicleType: string
}

const validationSchema =
    Yup.object().shape({
        sourceRegistration: Yup.mixed().required(validateForm.required),
    })


export const Step4: FC<Step4Props> = ({step, setStep, vehicleType}) => {
    const dispatch = useDispatch<AppDispatch>()
    const {values, setFieldError, getFieldProps, setFieldTouched}: any = useFormikContext()
    const chosenTour = useSelector(selectCurrentChoosenTourData)
    const currentDate = moment().format('DD.MM.YYYY');
    const dataSourceRegistrationFromApi = useSelector(selectSourceRegirstration)
    let dataSourceRegistration: any = []
    const user = useSelector(selectUserInfo)

    const onClickNext = useCallback(async () => {
        setFieldTouched(`sourceRegistration`, true)
        try {
            const result = await validationSchema.validate(values, {abortEarly: false})
            if (result.sourceRegistration) {
                setStep(step + 1);
                dispatch(setStepsState5(false));
            }
        } catch (err: any) {
            err.inner.forEach((error: any) => {
                setFieldError(error.path, error.errors[0])
            })
        }
    }, [setFieldError, values])

    function dataPreparation() {
        dataSourceRegistration = Object.keys(dataSourceRegistrationFromApi).map(key => {
            return {
                value: key,
                label: dataSourceRegistrationFromApi[key]
            };
        });
    }


    dataPreparation()
    useCostCalculation(vehicleType)


    return (
        <div className={style['step-4']}>
            <div className={style.additional}>
                <div className={style.title}>
                    Дополнительные сведения
                </div>
                <div className={style['additional-wrap']}>
                    <FieldFormik name='data.applicationDate' text={currentDate} typeInput='input-date'
                                 label='Дата заявки' svg={true}/>
                    {user.roleId !== 2 ?
                        <SelectCustom className={'select-full'} data={dataSourceRegistration} name='sourceRegistration'
                                      heading='Выберите из списка'
                                      placeholder="Выберите из списка" labelTitle='Путь оформления'></SelectCustom>
                        :
                        <FieldFormik name='data.sourceRegistration' text={'Агент'} typeInput='input-date'
                                     label='Путь оформления' className={style['input-payment']}/>
                    }
                </div>
                <TextAreaFormik name={'data.additionalExcursions'} label={'Дополнительные экскурсии'}
                                placeholder={'Пример: Название экскурсии'}/>
                <div className={style.paymentBlock}>
                    <div className={style['paymentBlock-title']}>
                        Данные об оплате
                    </div>
                    <div className={style['paymentBlock-wrapper']}>
                        <div className={style.firstBlock}>
                            <FieldFormik name='data.finalFirstPartPriceByn'
                                         label='Стоимость услуги (Первый взнос), BYN'
                                         placeholder='Первоначальный взнос, BYN'
                                         typeInput='input-currency' type={'number'}
                                         className={style['input-payment']}/>
                            {user.roleId === 2 && <div className={style.message}>Сумма указана за вычетом комиссии</div>}
                        </div>
                        <FieldFormik name='data.finalSecondPartPriceByn'
                                     text={getFieldProps('data.finalSecondPartPriceByn').value}
                                     typeInput='input-date'
                                     label='Стоимость услуги (Второй взнос), BYN' className={style['input-payment']}/>
                        <FieldFormik name='data.finalPriceUsd'
                                     text={getFieldProps('data.finalPriceUsd').value + " " + chosenTour.currency}
                                     typeInput='input-date'
                                     label='Валютная часть' className={style['input-payment']}/>
                    </div>
                </div>
            </div>
            <Button onClickFunc={onClickNext} className={'btn-second'}>Следующий шаг</Button>
        </div>
    )
}
