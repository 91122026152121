import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import Litepicker from 'litepicker'
import moment from "moment";
import cn from "classnames";
import {Button} from "@src/components/Button/Button";
import {Calendar} from "@src/components/AllSvg/Calendar";

import '@src/components/common/Datepicker/Datepicker.scss';
import {useField, useFormikContext} from "formik";
import style from "@src/components/common/FieldFormik/FieldFormik.module.scss";

type DatepickerProps = {
    date: {
        startDate: string
        endDate: string
    }
    setDate: (data: {
        startDate: string
        endDate: string
    }) => void
    maxDays?: number
    maxDate?: string
    minDate?: string
    className?: string
    name?: string | any
    placeholder?: string


}
export const Datepicker: FC<DatepickerProps> = ({
                                                    date,
                                                    setDate,
                                                    maxDays,
                                                    maxDate,
                                                    minDate,
                                                    className,
                                                    name,
                                                    placeholder
                                                }) => {
    const [field, meta] = useField(name)
    const {setFieldValue} = useFormikContext()
    const litepickerContainer = useRef<HTMLDivElement | null>(null);
    const litepickerRef = useRef<HTMLInputElement | null>(null);
    const leftBlockRef = useRef<HTMLInputElement | null>(null);
    const [heightLitepicker, setHeightLitepicker] = useState<number | null>(null);
    const [widthLitepicker, setWidthLitepicker] = useState<number | null>(null);
    const [isFocused, setIsFocused] = useState<boolean>(false)
    const isError = useMemo(() => !isFocused && meta.error && meta.touched, [isFocused, meta.error, meta.touched])


    //убрать placeholder
    const removePlaceholder = () => {
        const placeholder = document.querySelector('.placeholder-block')
        if (placeholder) {
            placeholder.classList.remove('firstShow')
        }
    }

    //отслеживание изменения высоты litepicker, появление блока с кнопками
    const handleElementAppearance = () => {
        const targetElement = document.querySelector('.litepicker') as HTMLDivElement;
        if (targetElement) {
            const height = targetElement.offsetHeight;
            const width = targetElement.offsetWidth;
            setHeightLitepicker(height);
            setWidthLitepicker(width);
        }
        if (targetElement && targetElement.style.display === 'block') {
            if (leftBlockRef.current) {
                leftBlockRef.current.style.opacity = `1`
                leftBlockRef.current.style.visibility = `visible`
            }
        } else {
            if (leftBlockRef.current) {
                leftBlockRef.current.style.opacity = `0`
                leftBlockRef.current.style.visibility = `hidden`
            }
        }
    };

    window.addEventListener('click', handleElementAppearance)

    const handleDateButtonClick = (type: string) => {
        let startDate, endDate;
        switch (type) {
            case 'today':
                startDate = moment();
                endDate = moment();
                break;
            case 'tomorrow':
                startDate = moment().add(1, 'days');
                endDate = moment().add(1, 'days');
                break;
            case 'next7days':
                startDate = moment();
                endDate = moment().add(6, 'days');
                break;
            case 'next30days':
                startDate = moment();
                endDate = moment().add(29, 'days');
                break;
            case 'thisMonth':
                startDate = moment().startOf('month');
                endDate = moment().endOf('month');
                break;
            case 'nextMonth':
                startDate = moment().add(1, 'month').startOf('month');
                endDate = moment().add(1, 'month').endOf('month');
                break;
            default:
                // Default to today if no valid type is provided
                startDate = moment();
                endDate = moment();
        }
        setDate({
            startDate: startDate.format('DD.MM.YYYY'),
            endDate: endDate.format('DD.MM.YYYY'),
        });
        setFieldValue(name, `${startDate.format('DD.MM.YYYY')} - ${endDate.format('DD.MM.YYYY')}`);
    };

    useEffect(() => {
        if (leftBlockRef.current && window.innerWidth > 575) {
            leftBlockRef.current.style.height = `${heightLitepicker}px`
            const leftBlockWidth = leftBlockRef.current.offsetWidth
            leftBlockRef.current.style.left = `calc(-${leftBlockWidth}px + 7rem)`
        } else if (leftBlockRef.current && window.innerWidth < 575) {
            leftBlockRef.current.style.height = `${heightLitepicker}px`
            leftBlockRef.current.style.right = `${widthLitepicker}px`
        }
    }, [heightLitepicker, widthLitepicker]);


    useEffect(() => {
        let currentLitepickerInstance: Litepicker | null = null;
        // eslint-disable-next-line no-new
        if (litepickerRef.current) {
            currentLitepickerInstance = new Litepicker({
                element: litepickerRef.current,
                maxDays,
                maxDate,
                minDate,
                buttonText: {
                    apply: 'Применить',
                    cancel: 'Отмена',
                    previousMonth: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="">\n' +
                        '<path fillRule="evenodd" clipRule="evenodd" d="M11 12.9001L10.3 13.6001L5 8.30011L10.3 3.00012L11 3.70014L6.40002 8.30011L11 12.9001Z" fill="#232323"/>\n' +
                        '</svg>',
                    nextMonth: '<svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="">\n' +
                        '<path fillRule="evenodd" clipRule="evenodd" d="M1.22383e-07 1.09985L0.700012 0.399902L6 5.69989L0.700014 10.9999L1.73096e-06 10.2999L4.59998 5.69989L1.22383e-07 1.09985Z" fill="#232323"/>\n' +
                        '</svg>',
                    reset: '<svg>...</svg>',
                },
                placeholder: 'Даты тура',
                singleMode: false,
                format: 'DD.MM.YYYY',
                lang: 'ru',
                resetButton: false,
                parentEl: litepickerContainer.current,
                tooltipText: {
                    one: 'день',
                    other: 'дней',
                    // @ts-ignore
                    few: 'дня',
                    many: 'дней',
                },
                setup: ((picker) => {
                    picker.on('selected', (date1, date2) => {
                        setDate({
                            startDate: date1.format('DD.MM.YYYY'),
                            endDate: date2.format('DD.MM.YYYY'),
                        })
                        setFieldValue(name, litepickerRef.current?.value);
                    })
                }),

            })
        }
        return () => {
            if (currentLitepickerInstance) {
                currentLitepickerInstance.destroy();
            }
        };
        // eslint-disable-next-line
    }, [date])

    const handleFocus = useCallback(() => {
        setIsFocused(true)
    }, [])

    //при blur
    const handleBlur = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        setIsFocused(false)
        field.onBlur(e)
    }, [field])

    return (
        <div ref={litepickerContainer} className={cn('datepicker', className)}>
            <div ref={leftBlockRef} className={'leftBlock'}>
                <Button className={'btn-datepicker'}
                        onClickFunc={() => handleDateButtonClick('today')}>Сегодня</Button>
                <Button className={'btn-datepicker'}
                        onClickFunc={() => handleDateButtonClick('tomorrow')}>Завтра</Button>
                <Button className={'btn-datepicker'} onClickFunc={() => handleDateButtonClick('next7days')}>Ближайшие
                    7 дней</Button>
                <Button className={'btn-datepicker'} onClickFunc={() => handleDateButtonClick('next30days')}>Ближайшие
                    30 дней</Button>
                <Button className={'btn-datepicker'} onClickFunc={() => handleDateButtonClick('thisMonth')}>Этот
                    месяц</Button>
                <Button className={'btn-datepicker'} onClickFunc={() => handleDateButtonClick('nextMonth')}>Следующий
                    месяц</Button>
            </div>
            <div className={cn('wrapInput', ` ${field.value !== '' ? 'svg-fill' : ''}`)}
                 onClick={() => removePlaceholder()}>
                <Calendar/>
                <input
                    {...field}
                    className={cn(style.input, isError && style.input_error, className, `${field.value ? style['input-fill'] : ''}`)}
                    type="text"
                    ref={litepickerRef}
                    placeholder={placeholder}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    readOnly
                />
            </div>
        </div>
    )
}
