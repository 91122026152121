import React, {FC} from "react";
import style from "@src/pages/History/HistoryDetails/HistoryDetails.module.scss"
import {Calendar} from "@src/components/AllSvg/Calendar";
import {OrdersHistoryElemType} from "@src/store/ducks/orders/types";
import {FieldHistory} from "@src/pages/History/HistoryDetails/FieldHistory";
import {HistoryOrderElem} from "@src/pages/History/HistoryOrderElem/HistoryOrderElem";
import {Button} from "@src/components/Button/Button";
import {useSelector} from "react-redux";
import {selectSourceRegirstration} from "@src/store/ducks/orders/selectors";
import {selectUserInfo} from "@src/store/ducks/user/selectors";
import {getVehicleTypeFromVehicleSlug} from "@src/customFunctions/General";

type TableHistoryElemProps = {
    data: OrdersHistoryElemType,
    setShowElem: any
};

export const HistoryDetails: FC<TableHistoryElemProps> = ({data, setShowElem}) => {
    const user = useSelector(selectUserInfo)

    const dataOrder = {
        startDate: data.startDate,
        endDate: data.endDate,
        applicationDate: data.applicationDate,
        sourceRegistration: data.sourceRegistration,
        additionalExcursions: data.additionalExcursions,
        title: data.tour.title,
        currency: data.trip.currency,
        finalFirstPartPriceByn: data.finalFirstPartPriceByn,
        finalSecondPartPriceByn: data.finalSecondPartPriceByn,
        finalPriceUsd: data.finalPriceUsd,
        busSlug: data.busSlug
    }

    const sourceRegistrationFromApi = useSelector(selectSourceRegirstration)
    const sourceRegistration = data.sourceRegistration

    const vehicleType = getVehicleTypeFromVehicleSlug(data.busSlug)

    return (
        <div className={style['history-details']}>
            <div className={style['final-order']}>
                <div className={style.title}>
                    Итоговые данные заявки
                </div>
                <div className={style['final-order-titleBlock']}>
                    <div className={style['tour-date']}>
                        <Calendar/>
                        <span>{dataOrder.startDate}</span> - <span>{dataOrder.endDate}</span>
                    </div>
                    <div className={style['tour-title']}>
                        {dataOrder.title}
                    </div>
                </div>
                <div className={style['final-order-list']}>
                    {data?.tourists.map((item: any, index: number) => (
                        <HistoryOrderElem key={index} data={item} index={index} places={data?.places[index]} vehicleType={vehicleType}/>
                    ))}
                </div>
                <div className={style.additional}>
                    <div className={style.title}>
                        Дополнительные сведения
                    </div>
                    <div className={style['additional-wrap']}>
                        <FieldHistory text={dataOrder.applicationDate}
                                      label='Дата заявки' svg={true}/>
                        <FieldHistory
                            text={dataOrder.sourceRegistration ? sourceRegistrationFromApi[sourceRegistration] : ''}
                            label='Путь оформления'/>
                    </div>
                    <FieldHistory text={dataOrder.additionalExcursions} label={'Дополнительные экскурсии'}
                                  className={style.textarea}/>
                    <div className={style.paymentBlock}>
                        <div className={style['paymentBlock-title']}>
                            Данные об оплате
                        </div>
                        <div className={style['paymentBlock-wrapper']}>
                            <div className={style.firstBlock}>
                                <FieldHistory text={dataOrder.finalFirstPartPriceByn}
                                              type={'number'}
                                              label='Стоимость услуги (Первый взнос)'/>
                                {user.roleId === 2 &&
                                    <div className={style.message}>Сумма указана за вычетом комиссии</div>}
                            </div>
                            <FieldHistory text={dataOrder.finalSecondPartPriceByn}
                                          type={'number'}
                                          label='Стоимость услуги (Второй взнос)'/>
                            <FieldHistory text={dataOrder.finalPriceUsd}
                                          type={'number'}
                                          label='Валютная часть'
                                          currency={dataOrder.currency}
                            />
                        </div>
                    </div>
                    <Button onClickFunc={() => setShowElem(false)} className={'btn-second'}>Скрыть детали
                        заказа</Button>
                </div>
            </div>
        </div>
    )
}


