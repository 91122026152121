import React, {Dispatch, FC, SetStateAction} from "react";
import {SelectCustom} from "@src/components/common/Select/SelectCustom";
import {FieldFormik} from "@src/components/common/FieldFormik/FieldFormik";

import style from '@src/pages/Tours/TourFormalization/components/steps/Step1/Step1.module.scss'
import {Button} from "@src/components/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentFreePlaces, selectOrdersPassengersQuantity} from "@src/store/ducks/orders/selectors";
import {selectAvailablePlaces} from "@src/store/ducks/user/selectors";
import {AppDispatch} from "@src/store/store";
import {setStepsState2} from "@src/store/ducks/orders/reducer";

type Step1Props = {
    date: string
    title: string
    setQuantity?: Dispatch<SetStateAction<string>>
    step?: any
    setStep? : any;
    name?: string
}


export const Step1: FC<Step1Props> = ({name, date, title, step, setStep}) => {
    const dispatch = useDispatch<AppDispatch>()
    const passengersQuantity = useSelector(selectOrdersPassengersQuantity)
    const availablePlaces = useSelector(selectAvailablePlaces)
    const currentFreePlaces = useSelector(selectCurrentFreePlaces)


    const smallerValue = Math.min(
        availablePlaces ?? Infinity,
        currentFreePlaces ?? Infinity
    );

    const dataQuantity = new Array(smallerValue).fill(null).map((item, index) => ({
        value: `${index + 1}`,
        label: `${index + 1}`
    }));

    return (
        <div className={style['step-1']}>
            <div className={style['subTitle']}>Название тура</div>
            <div className={style['title']}>{title}</div>
            <div className={style.wrapper}>
                <FieldFormik name='step1' text={date} typeInput='input-date' label='Дата проведения тура' svg={true}/>
                <SelectCustom className={'select-full'} name='passengersQuantity1' data={dataQuantity}
                              placeholder='Укажите количество' heading='Выберите количество'
                              labelTitle='Количество пассажиров' type='step1'/>
            </div>

            <Button
                disabled={!passengersQuantity.value}
                onClickFunc={() => {
                    setStep(step + 1);
                    dispatch(setStepsState2(false));
                }}
                className={'btn-second'}
            >
                Следующий шаг
            </Button>
        </div>
    )
}
