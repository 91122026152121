import React from "react";


export const Price = () => {
return (
    <svg width="100%" height="100%" viewBox="0 0 14.333 12.3333" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="Vector" d="M9.53052 5.16528C9.57837 5.26408 9.70654 5.35883 9.81445 5.37695L10.4524 5.48383C10.8604 5.55243 10.9563 5.85083 10.6624 6.14516L10.1665 6.64522C10.0825 6.72989 10.0364 6.89322 10.0625 7.01016L10.2043 7.62915C10.3164 8.11916 10.0583 8.30869 9.62842 8.05263L9.03052 7.69576C8.92261 7.63123 8.74463 7.63123 8.63452 7.69576L8.03662 8.05263C7.60864 8.30869 7.34863 8.11716 7.46069 7.62915L7.60278 7.01016C7.62866 6.89322 7.58276 6.72989 7.49878 6.64522L7.00269 6.14516C6.71069 5.85083 6.80469 5.55243 7.21265 5.48383L7.85059 5.37695C7.95654 5.35883 8.08472 5.26408 8.13257 5.16528L8.48462 4.45552C8.67651 4.0704 8.98853 4.0704 9.17847 4.45552L9.53052 5.16528Z" stroke="#0062AA" strokeOpacity="1.000000" strokeWidth="1.000000" strokeLinejoin="round"/>
        <path id="Vector" d="M4.49951 9.49997L4.49951 11.8333" stroke="#0062AA" strokeOpacity="1.000000" strokeWidth="1.000000" strokeLinejoin="round" strokeLinecap="round"/>
        <path id="Vector" d="M4.49951 0.5L4.49951 2.83333" stroke="#0062AA" strokeOpacity="1.000000" strokeWidth="1.000000" strokeLinejoin="round" strokeLinecap="round"/>
        <path id="Vector" d="M13.3132 1.85922C13.1118 1.55464 12.8616 1.28973 12.574 1.07645C11.7961 0.5 10.699 0.5 8.50488 0.5L5.82837 0.5C3.63428 0.5 2.53711 0.5 1.75952 1.07645C1.47168 1.28973 1.22144 1.55464 1.02002 1.85922C0.669922 2.3886 0.544922 3.05777 0.500244 4.08562C0.492676 4.26138 0.644043 4.39583 0.809814 4.39583C1.73389 4.39583 2.48267 5.18866 2.48267 6.16667C2.48267 7.14467 1.73389 7.93747 0.809814 7.93747C0.644043 7.93747 0.492676 8.07193 0.500244 8.24773C0.544922 9.2756 0.669922 9.94473 1.02002 10.4741C1.22144 10.7787 1.47168 11.0436 1.75952 11.2569C2.53711 11.8333 3.63428 11.8333 5.82837 11.8333L8.50488 11.8333C10.699 11.8333 11.7961 11.8333 12.574 11.2569C12.8616 11.0436 13.1118 10.7787 13.3132 10.4741C13.6636 9.94467 13.7886 9.27541 13.833 8.24741L13.833 4.08597C13.7886 3.05791 13.6636 2.38866 13.3132 1.85922Z" stroke="#0062AA" strokeOpacity="1.000000" strokeWidth="1.000000" strokeLinejoin="round"/>
    </svg>
)
}