import React, {useMemo} from "react";
import cn from "classnames";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {FieldFormik} from "@src/components/common/FieldFormik/FieldFormik";
import {Title} from "@src/components/Title/Title";
import {ProfileSvg} from "@src/components/AllSvg/ProfileSvg";
import {LoadImage} from "@src/components/AllSvg/LoadImage";
import {Button} from "@src/components/Button/Button";
import style from '@src/pages/Profile/ChangeProfileInfo/ChangeProfileInfo.module.scss'
import {UserType} from "@src/store/ducks/user/types";
import {changeImage, changeUser} from "@src/store/ducks/user/thunks";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "@src/store/store";
import {selectUserInfo} from "@src/store/ducks/user/selectors";
import {useNavigate} from "react-router-dom";


export const ChangeProfileInfo = () => {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate();
    const data: UserType = useSelector(selectUserInfo)

    const onChangeHandler = (e: any) => {
        const formData = new FormData();
        formData.append("user_id", String(data.id));
        formData.append("file", e.target.files[0]);
        dispatch(changeImage(formData))
    };

    const validationSchema = useMemo(() => (
        Yup.object().shape({})
    ), [])

    const initialValues = useMemo(() => ({
        user: {
            id: data.id,
            email: data.email,
            usedPlaces: data.usedPlaces,
            companyName: data.companyName,
            unp: data.unp,
            legalAddress: data.legalAddress,
            bankingDetails: data.bankingDetails,
            sertificateDetails: data.sertificateDetails,
            passportExpiryDate: data.passportExpiryDate,
            passportSeriesAndNumber: data.passportSeriesAndNumber,
            passportIssuedBy: data.passportIssuedBy,
            passportPersonalNumber: data.passportPersonalNumber,
            birthDateIp: data.birthDateIp,
            phoneNumberIp: data.phoneNumberIp,
            residenceAddressIp: data.residenceAddressIp,
            urCompanyName: data.urCompanyName,
            urSertificateDetails: data.urSertificateDetails,
            urFio: data.urFio,
            urBank: data.urBank,
            urBik: data.urBik,
            urPaymentNumber: data.urPaymentNumber,
            urUnp: data.urUnp,
            urOkpo: data.urOkpo,
            urEmail: data.urEmail,
            urPhone: data.urPhone,
            urAddress: data.urAddress,
            type: data.type,
            certificate: data.certificate,
            bankingDetailsNumber:data.bankingDetailsNumber,
            emailIp:data.emailIp
        } as UserType,
    }), [data])


    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={({user}) => {
                dispatch(changeUser(user, navigate))
            }}
            validationSchema={validationSchema}
        >
            {({values, resetForm}) => (
                <Form className={style['change-profile']}>
                    <Title title={'Редактирование данных'} svgComponent={ProfileSvg}></Title>
                    <div className={cn(style['change-profile-info'])}>
                        <div className={cn(style['change-profile-info-left'])}>
                            <div className={style['image-block']}>
                                {values.user.certificate ? <img src={values.user.certificate} alt="image"/> :
                                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" alt='image'/>}
                            </div>
                            <div className={style.imageLoad}>
                                <label htmlFor="fileInput">
                                    <input id="fileInput" type="file" onChange={onChangeHandler}/>
                                    <Button svgComponent={LoadImage}
                                            className={'btn-load'}>
                                        Заменить фото
                                    </Button>
                                </label>
                            </div>
                        </div>

                        {initialValues.user.type === 1 &&
                            <div className={cn(style['change-profile-info-right'])}>
                                <FieldFormik
                                    name="user.companyName"
                                    placeholder={initialValues.user.companyName}
                                    label='Наименование'
                                    typeInput='input-usual'
                                />
                                <FieldFormik
                                    name="user.sertificateDetails"
                                    placeholder={initialValues.user.sertificateDetails}
                                    label='Свидетельство о государственной регистрации'
                                    typeInput='input-usual'
                                />
                                <FieldFormik
                                    name="user.bankingDetailsNumber"
                                    placeholder={initialValues.user.bankingDetailsNumber}
                                    label='Р/С'
                                    typeInput='input-usual'
                                />

                                <FieldFormik
                                    name="user.bankingDetails"
                                    placeholder={initialValues.user.bankingDetails}
                                    label='Данные банка'
                                    typeInput='input-usual'
                                />
                                <FieldFormik
                                    name="user.residenceAddressIp"
                                    placeholder={initialValues.user.residenceAddressIp}
                                    label='Адрес прописки'
                                    typeInput='input-usual'
                                />
                                <FieldFormik
                                    name="user.phoneNumberIp"
                                    placeholder={initialValues.user.phoneNumberIp}
                                    label='Телефон для связи'
                                    typeInput='input-usual'
                                />
                                <FieldFormik
                                    name="user.emailIp"
                                    placeholder={initialValues.user.emailIp}
                                    label='E-mail'
                                    typeInput='input-usual'
                                />
                            </div>
                        }
                        {initialValues.user.type === 2 &&
                            <div className={cn(style['change-profile-info-right'])}>
                                <FieldFormik
                                    name="user.urCompanyName"
                                    placeholder={initialValues.user.urCompanyName}
                                    label='Наименование'
                                    typeInput='input-usual'
                                />
                                <FieldFormik
                                    name="user.urSertificateDetails"
                                    placeholder={initialValues.user.urSertificateDetails}
                                    label='Свидетельство о государственной регистрации'
                                    typeInput='input-usual'
                                />
                                <FieldFormik
                                    name="user.urFio"
                                    placeholder={initialValues.user.urFio}
                                    label='ФИО'
                                    typeInput='input-usual'
                                />
                                <FieldFormik
                                    name="user.urBank"
                                    placeholder={initialValues.user.urBank}
                                    label='Наименвание банка'
                                    typeInput='input-usual'
                                />
                                <FieldFormik
                                    name="user.urPaymentNumber"
                                    placeholder={initialValues.user.urPaymentNumber}
                                    label='Р/С'
                                    typeInput='input-usual'
                                />
                                <FieldFormik
                                    name="user.urBik"
                                    placeholder={initialValues.user.urBik}
                                    label='BIC Банка'
                                    typeInput='input-usual'
                                />
                                <FieldFormik
                                    name="user.urUnp"
                                    placeholder={initialValues.user.urUnp}
                                    label='УНП'
                                    typeInput='input-usual'
                                />
                                <FieldFormik
                                    name="user.urOkpo"
                                    placeholder={initialValues.user.urOkpo}
                                    label='ОКПО'
                                    typeInput='input-usual'
                                />
                                <FieldFormik
                                    name="user.urEmail"
                                    placeholder={initialValues.user.urEmail}
                                    label='E-mail'
                                    typeInput='input-usual'
                                />
                                <FieldFormik
                                    name="user.urPhone"
                                    placeholder={initialValues.user.urPhone}
                                    label='Телефон для связи'
                                    typeInput='input-usual'
                                />
                                <FieldFormik
                                    name="user.urAddress"
                                    placeholder={initialValues.user.urAddress}
                                    label='Юридический адрес'
                                    typeInput='input-usual'
                                />
                            </div>
                        }
                    </div>
                    <div className={style['buttons-block']}>
                        <Button htmlType={'submit'} className={'btn-save'}>Сохранить данные</Button>
                        <Button onClickFunc={() => {
                            resetForm();
                            navigate("/profile");
                        }} className={'btn-cancel'}>Отменить изменения</Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

