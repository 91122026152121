import React, {useEffect, useMemo, useState} from "react";
import moment from 'moment'
import {SelectCustom} from "@src/components/common/Select/SelectCustom";
import {Datepicker} from "@src/components/common/Datepicker/Datepicker";
import {Clear} from "@src/components/AllSvg/Clear";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {FieldFormik} from "@src/components/common/FieldFormik/FieldFormik";

import style from "@src/pages/Tours/TourChoise/components/TourSelect/TourSelect.module.scss";
import {Button} from "@src/components/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {
    selectTripsDataCities,
    selectTripsDataCountry,
    selectTripsDataFilter,
    selectTripsDataTours, selectTripsResultHasMore, selectTripsResultNextPage
} from "@src/store/ducks/trips/selectors";
import {getTripsResultDataAsinc, getTripsResultDataNextPage} from "@src/store/ducks/trips/thunks";
import {AppDispatch} from "@src/store/store";
import {
    filterTripsDataCity,
    filterTripsDataCountry,
    filterTripsDataToursCity,
    filterTripsDataToursCountry
} from "@src/store/ducks/trips/reducer";
import cn from "classnames";
import {NavLink} from "react-router-dom";
import {setSuccessPage, setTourChoiseOpen, setTourFormalisationOpen} from "@src/store/ducks/app/reducer";
import logo from "@src/images/logo.svg";
import burgerClose from "@src/images/burger-close.svg";
import filter from "@src/images/filter-history.svg";


export const TourSelect = () => {
    const dispatch = useDispatch<AppDispatch>()
    const [openMobileSelect, setOpenMobileSelect] = useState(false)
    const [valuesFromFormik, setValuesFromFormik] = useState<any>({
        page: 1,
        countryId: null,
        cityId: null,
        tourId: null,
        dates: '',
        minPlaces: '',
        priceFrom: '',
        priceTo: '',
    })
    let nextPage = useSelector(selectTripsResultNextPage)
    const [fetching, setFetching] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [allowLoading, setAllowLoading] = useState(false)


    useEffect(() => {
        dispatch(getTripsResultDataAsinc({}))
        setFetching(false)
    }, []);


    useEffect(() => {
        updatePropertyValue(Number(nextPage))
    }, [nextPage, allowLoading]);

    const updatePropertyValue = (currentPage: number) => {
        if (valuesFromFormik) {
            setValuesFromFormik({
                ...valuesFromFormik,  // Создаем копию объекта
                page: currentPage    // Обновляем значение одного свойства
            });
        }
    };

    useEffect(() => {
        if (fetching && nextPage !== 0) {
            try {
                if (nextPage !== currentPage) {
                    dispatch(getTripsResultDataNextPage(valuesFromFormik))
                    setCurrentPage(currentPage + 1)
                }
            } finally {
                setFetching(false)
            }
        }
    }, [fetching])


    useEffect(() => {
        document.body.addEventListener('scroll', () => scrollHandler());

        return function () {
            document.body.removeEventListener('scroll', () => scrollHandler());
        }
    }, []);

    const scrollHandler = () => {
        let scrollTop = document.body.scrollTop;
        let totalHeight = document.body.scrollHeight;
        let windowHeight = window.innerHeight;
        if (totalHeight - (scrollTop + windowHeight) <= 50 && nextPage !== 0) {
            setFetching(true);
        }
    }


    const [date, setDate] = useState({
        startDate: moment().format('DD.MM.YYYY'),
        endDate: moment().format('DD.MM.YYYY'),
    })

    const dataCountryFromApi = useSelector(selectTripsDataCountry)
    const dataCityFromApi = useSelector(selectTripsDataCities)
    const dataToursFromApi = useSelector(selectTripsDataTours)
    const tripsDataFilter = useSelector(selectTripsDataFilter)

    let dataCountry: any = []
    let dataCity: any = []
    let dataTours: any = []

    function dataPreparation(tripsDataFilter: any) {
        dataCountry.length = 0
        dataCity.length = 0
        dataTours.length = 0

        dataCountryFromApi.forEach(item => {
            if (tripsDataFilter.countryFilter === item.id && tripsDataFilter.toursFilter.countryFilterId === item.id) {
                dataCountry.push({value: item.id, label: item.title, id: item.id})
            } else if (tripsDataFilter.countryFilter === item.id && !tripsDataFilter.toursFilter.countryFilterId) {
                dataCountry.push({value: item.id, label: item.title, id: item.id})
            } else if (tripsDataFilter.toursFilter.countryFilterId === item.id && !tripsDataFilter.cityFilter) {
                dataCountry.push({value: item.id, label: item.title, id: item.id})
            } else if (!tripsDataFilter.toursFilter.countryFilterId && !tripsDataFilter.cityFilter) {
                dataCountry.push({value: item.id, label: item.title, id: item.id})
            }
        })
        dataCityFromApi.forEach(item => {
            if (tripsDataFilter.countryFilter === item.countryId && tripsDataFilter.toursFilter.cityFilterId === item.id) {
                dataCity.push({value: item.id, label: item.title, id: item.id, countryId: item.countryId})
            } else if (tripsDataFilter.countryFilter === item.countryId && !tripsDataFilter.toursFilter.cityFilterId) {
                dataCity.push({value: item.id, label: item.title, id: item.id, countryId: item.countryId})
            } else if (tripsDataFilter.toursFilter.cityFilterId === item.id && !tripsDataFilter.countryFilter) {
                dataCity.push({value: item.id, label: item.title, id: item.id, countryId: item.countryId})
            } else if (!tripsDataFilter.toursFilter.cityFilterId && !tripsDataFilter.countryFilter) {
                dataCity.push({value: item.id, label: item.title, id: item.id, countryId: item.countryId})
            }
        })
        dataToursFromApi.forEach(item => {
            if (tripsDataFilter.countryFilter === item.countryId && tripsDataFilter.cityFilter === item.cityId) {
                dataTours.push({
                    value: item.id,
                    label: item.title,
                    id: item.id,
                    countryId: item.countryId,
                    cityId: item.cityId
                })
            } else if (tripsDataFilter.countryFilter === item.countryId && !tripsDataFilter.cityFilter) {
                dataTours.push({
                    value: item.id,
                    label: item.title,
                    id: item.id,
                    countryId: item.countryId,
                    cityId: item.cityId
                })
            } else if (tripsDataFilter.cityFilter === item.cityId && !tripsDataFilter.countryFilter) {
                dataTours.push({
                    value: item.id,
                    label: item.title,
                    id: item.id,
                    countryId: item.countryId,
                    cityId: item.cityId
                })
            } else if (!tripsDataFilter.cityFilter && !tripsDataFilter.countryFilter) {
                dataTours.push({
                    value: item.id,
                    label: item.title,
                    id: item.id,
                    countryId: item.countryId,
                    cityId: item.cityId
                })
            }
        })
    }

    dataPreparation(tripsDataFilter)

    useEffect(() => {
        dataPreparation(tripsDataFilter)
    }, [tripsDataFilter, dataPreparation])


    const validationSchema = useMemo(() => (
        Yup.object().shape({})
    ), [])

    const initialValues = useMemo(() => ({
        country: null,
        city: null,
        tour: null,
        filterPropsData: {
            page: 1,
            countryId: null,
            cityId: null,
            tourId: null,
            dates: '',
            minPlaces: '',
            priceFrom: '',
            priceTo: '',
        },
    }), [])

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                dispatch(getTripsResultDataAsinc(values.filterPropsData))
                setValuesFromFormik(values.filterPropsData)
                setAllowLoading(!allowLoading)
                setFetching(false);

            }}
            onReset={(values) => {
                // Выполнить дополнительные действия при сбросе формы
                dispatch(getTripsResultDataAsinc({}))
                setValuesFromFormik({
                    page: 1,
                    countryId: null,
                    cityId: null,
                    tourId: null,
                    dates: '',
                    minPlaces: '',
                    priceFrom: '',
                    priceTo: '',
                })
                dispatch(filterTripsDataCountry(null))
                dispatch(filterTripsDataCity(null))
                dispatch(filterTripsDataToursCountry(null))
                dispatch(filterTripsDataToursCity(null))
                setAllowLoading(!allowLoading)
                setCurrentPage(1)
                setFetching(false);
            }}
            validationSchema={validationSchema}

        >
            {({resetForm}) => (
                <Form className={style.form}>
                    <div className={style.filter} onClick={() => setOpenMobileSelect(!openMobileSelect)}>
                        <img src={filter} alt={filter}/>
                        Фильтрация туров
                    </div>
                    <div className={style.tourSelect}>
                        <SelectCustom name='country' className={'grid-item-1'} data={dataCountry}
                                      heading='Выберите страну'
                                      placeholder="Страна"/>
                        <SelectCustom name='city' className={'grid-item-2'} data={dataCity}
                                      heading='Выберите город'
                                      placeholder="Выберите город"/>
                        <SelectCustom name='tour' className={'grid-item-3'} data={dataTours}
                                      heading='Название тура'
                                      placeholder="Название тура"/>
                        <FieldFormik
                            name="filterPropsData.minPlaces"
                            placeholder={'Осталось мест'}
                            className={'grid-item-4'}
                            typeInput='input-select'
                        />
                        {!openMobileSelect &&
                            <Datepicker name='filterPropsData.dates' className={'grid-item-5'} date={date}
                                        setDate={setDate} placeholder={'Даты тура'}/>}
                        <FieldFormik
                            name="filterPropsData.priceFrom"
                            placeholder={"Цена от"}
                            className={'grid-item-6'}
                            typeInput='input-select'
                        />
                        <FieldFormik
                            name="filterPropsData.priceTo"
                            placeholder={"Цена до"}
                            className={'grid-item-7'}
                            typeInput='input-select'
                        />
                    </div>
                    <div className={style.blockFilter}>
                        <Button onClickFunc={() => resetForm()} className={style.clearSelect} svgComponent={Clear}>Сбросить
                            фильтры</Button>
                        <Button htmlType="submit" className={'btn-second'}>Подобрать туры</Button>
                    </div>
                    <div
                        className={cn(style['tourSelect-mobile'], openMobileSelect ? style['tourSelect-mobile-active'] : '')}>
                        <div className="container">
                            <div className={style['mobile-steps-inner']}>
                                <div className={style['steps-mb-logo']}>
                                    <NavLink
                                        to="/tours"
                                        onClick={() => {
                                            dispatch(setTourFormalisationOpen(false));
                                            dispatch(setSuccessPage(false));
                                            dispatch(setTourChoiseOpen(true));
                                        }}>
                                        <div className={style.logo}>
                                            <img
                                                src={logo}
                                                alt="logo"
                                            />
                                        </div>
                                    </NavLink>
                                </div>
                                <div className={style.burger}>
                                    <div onClick={() => setOpenMobileSelect(false)} className={style['burger-close']}>
                                        <img
                                            src={burgerClose}
                                            alt="burger-close"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={style['tabsStep-mobile-title']}>Фильтрация туров</div>
                            <div className={style.tabs}>
                                <div className={style.tourSelect}>
                                    <SelectCustom name='country' className={'grid-item-1'} data={dataCountry}
                                                  heading='Выберите страну'
                                                  placeholder="Страна"/>
                                    <SelectCustom name='city' className={'grid-item-2'} data={dataCity}
                                                  heading='Выберите город'
                                                  placeholder="Выберите город"/>
                                    <SelectCustom name='tour' className={'grid-item-3'} data={dataTours}
                                                  heading='Название тура'
                                                  placeholder="Название тура"/>
                                    <FieldFormik
                                        name="filterPropsData.minPlaces"
                                        placeholder={'Осталось мест'}
                                        className={'grid-item-4'}
                                        typeInput='input-select'
                                    />
                                    {openMobileSelect &&
                                        <Datepicker name='filterPropsData.dates' className={'grid-item-5'} date={date}
                                                    setDate={setDate} placeholder={'Даты тура'}/>}

                                    <FieldFormik
                                        name="filterPropsData.priceFrom"
                                        placeholder={"Цена от"}
                                        className={'grid-item-6'}
                                        typeInput='input-select'
                                    />
                                    <FieldFormik
                                        name="filterPropsData.priceTo"
                                        placeholder={"Цена до"}
                                        className={'grid-item-7'}
                                        typeInput='input-select'
                                    />
                                </div>
                                <div className={style.blockFilter}>
                                    <Button onClickFunc={() => resetForm()} className={style.clearSelect}
                                            svgComponent={Clear}>Сбросить
                                        фильтры</Button>
                                    <Button onClickFunc={() => setOpenMobileSelect(false)} htmlType="submit"
                                            className={'btn-second'}>Подобрать туры</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

