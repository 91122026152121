import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "@src/store/store";
import {NavLink} from "react-router-dom";
import logo from '@src/images/logo.svg'
import burgerClose from '@src/images/burger-close.svg'
import burger from '@src/images/burger.svg'
import {
    setMobileMenuActive,
    setSuccessPage,
    setTourChoiseOpen,
    setTourFormalisationOpen
} from "@src/store/ducks/app/reducer";
import style from '@src/components/HeaderMobile/HeaderMobile.module.scss'
import cn from "classnames";
import {TourSvg} from "@src/components/AllSvg/ToursSvg";
import {HistorySvg} from "@src/components/AllSvg/HistorySvg";
import {ProfileSvg} from "@src/components/AllSvg/ProfileSvg";
import {Button} from "@src/components/Button/Button";
import {logout} from "@src/store/ducks/auth/thunks";
import {ExitSvg} from "@src/components/AllSvg/ExitSvg";
import {selectUserInfo} from "@src/store/ducks/user/selectors";


export const HeaderMobile = () => {
    const dispatch = useDispatch<AppDispatch>()
    const userName = useSelector(selectUserInfo)
    const [burgerOpen, setBurgerOpen] = useState(false)

    useEffect(() => {
        if(burgerOpen) {
            document.body.style.overflowY= 'hidden'
        } else {
            document.body.style.overflowY= 'auto'
        }
    }, [burgerOpen])

    return (
        <div className={cn(style['mobile-header'], burgerOpen ? style['background-white'] : style['background-grey'])}>
            <div className="container">
                <div className={style['mobile-header-inner']}>
                    <div className={style['logo-mb']}>
                        <NavLink
                            to="/tours"
                            onClick={() => {
                                dispatch(setTourFormalisationOpen(false));
                                dispatch(setSuccessPage(false));
                                dispatch(setTourChoiseOpen(true));
                            }}>
                            <div className={style.logo}>
                                <img
                                    src={logo}
                                    alt="logo"
                                />
                            </div>
                        </NavLink>
                    </div>
                    <div onClick={() => {
                        setBurgerOpen(!burgerOpen)
                        dispatch(setMobileMenuActive(burgerOpen))
                    }} className={style.burger}>
                        {burgerOpen ? <div className={style['burger-close']}>
                                <img
                                    src={burgerClose}
                                    alt="burger-close"
                                />
                            </div>
                            :
                            <div className={style.burger}>
                                <div className={style.burgerSvg}>
                                    <img
                                        src={burger}
                                        alt="burger"
                                    />
                                </div>
                                <span>Меню</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className={cn(style['active-menu'], burgerOpen ? style['active-menu-open'] : '')}>
                <div className="container">
                    <div className={style['active-menu-inner']}>
                        <div className={style.name}>
                            <span>Партнер / Сотрудник</span>
                            <span>{userName.fio}</span>
                        </div>
                        <div className={style['tabs-wrapper']}>
                            <div className={style.title}>
                                Основные разделы
                            </div>
                            <div className={style.tabs}>
                                <NavLink
                                    to="/tours"
                                    onClick={() => {
                                        dispatch(setTourFormalisationOpen(false));
                                        setBurgerOpen(!burgerOpen)
                                    }}>
                                    <TourSvg/>
                                    <span>Таблица рейсов</span>
                                </NavLink>
                                <NavLink
                                    to="/history"
                                    onClick={() => {
                                        setBurgerOpen(!burgerOpen)
                                    }}
                                >
                                    <HistorySvg/>
                                    <span>История</span>
                                </NavLink>
                                <NavLink
                                    to="/profile"
                                    onClick={() => {
                                        setBurgerOpen(!burgerOpen)
                                    }}
                                >
                                    <ProfileSvg/>
                                    <span>Профиль</span>
                                </NavLink>
                            </div>
                        </div>
                        <Button onClickFunc={() => {
                            dispatch(logout())
                        }} svgComponent={ExitSvg} className={style.exit}>
                            Выйти из аккаунта
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
