import React, {useMemo} from 'react'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import {Button} from '@src/components/Button/Button'
import {FieldFormik} from '@src/components/common/FieldFormik/FieldFormik'
import {TitleLogin} from "@src/components/TitleLogin/TitleLogin";
import {validateForm} from '@src/messages/validateForm'
import style from '@src/pages/Profile/ChangePassword/ChangePassword.module.scss'
import {Title} from "@src/components/Title/Title";
import {PasswordSvg} from "@src/components/AllSvg/PasswordSvg";
import {useNavigate} from "react-router-dom";
import {changePasswordAsinc} from "@src/store/ducks/auth/thunks";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "@src/store/store";
import {selectUserInfo} from "@src/store/ducks/user/selectors";


const validationSchema = Yup.object().shape({

    oldPassword: Yup.string().required(validateForm.required),
    password: Yup.string().required(validateForm.required)
        .notOneOf([Yup.ref('oldPassword')], 'Новый пароль идентичен старому')
        .min(8, 'Минимальная длина строки 8 символов'),
    confirmPassword: Yup.string().required(validateForm.required)
        .oneOf([Yup.ref('password')/*, null*/], 'Пароли не совпадают')
        .min(8, 'Минимальная длина строки 8 символов'),
})


export const ChangePassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>()
    const currentUser = useSelector(selectUserInfo)
    const initialValues = useMemo(() => ({oldPassword: '', password: '', confirmPassword: ''}), [])
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(value) => {
                dispatch(changePasswordAsinc(currentUser.email, value.password, value.confirmPassword, navigate))
            }}
            validationSchema={validationSchema}
        >
            {({resetForm}) => (
                <Form className={style['changePassword-container']}>
                    <Title title={'Изменение пароля'} svgComponent={PasswordSvg}></Title>
                    <div className={style['changePassword']}>
                        <TitleLogin/>
                        <div className={style['form-container']}>
                            <FieldFormik
                                name="oldPassword"
                                placeholder="Действующий пароль"
                                label="Действующий пароль"
                                typeInput='input-password'
                            />
                            <FieldFormik
                                name="password"
                                placeholder="Минимум 8 символов"
                                label="Введите новый пароль"
                                typeInput='input-password'
                            />
                            <FieldFormik
                                name="confirmPassword"
                                placeholder="Минимум 8 символов"
                                label="Повторите новый пароль"
                                typeInput='input-password'
                            />
                        </div>
                        <div className={style['buttons-block']}>
                            <Button htmlType={'submit'} className={'btn-save btn-width'}>Сохранить данные</Button>
                            <Button onClickFunc={() => {
                                resetForm();
                                navigate("/profile");
                            }} className={'btn-cancel btn-width'}>Отменить изменения</Button>
                        </div>
                    </div>
                    {/*                    {backError && isString(backError) && <div className={style.error}>{backError}</div>}*/}
                </Form>
            )}
        </Formik>
    )
}
