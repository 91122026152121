import cn from 'classnames'
import {NavLink} from 'react-router-dom'
import style from '@src/pages/Errors/Error404.module.scss'
import {Button} from "@src/components/Button/Button";
import {TitleLogin} from "@src/components/TitleLogin/TitleLogin";
import image from '@src/images/404.svg'

export const Error404 = () => (
    <div className={cn(style.containerError404)}>
        <div className={style.errorPage}>
            <TitleLogin className={style.titleLogin}/>
            <div className={style.image}>
                <img src={image} alt="404"/>
            </div>
            <p className={style.text}>К сожалению такой страницы не существует</p>
            <NavLink
                to="/tours"
            >
                <Button
                    type="secondary"
                    className={cn('btn', 'btn-save')}
                >
                    Вернуться на главную
                </Button>
            </NavLink>
        </div>
    </div>
)
