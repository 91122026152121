import React, {FC} from "react";
import {useFormikContext} from "formik";
import {useSelector} from "react-redux";
import {selectCurrentChoosenTourData, selectSourceRegirstration} from "@src/store/ducks/orders/selectors";
import {Calendar} from "@src/components/AllSvg/Calendar";
import style from '@src/pages/Tours/TourFormalization/components/steps/Step5/Step5.module.scss'
import {OrderElem} from "@src/pages/Tours/TourFormalization/components/steps/Step5/OrderElem";
import {FieldFormik} from "@src/components/common/FieldFormik/FieldFormik";
import {TextAreaFormik} from "@src/components/common/TextAreaFormik/TextAreaFormik";
import {Button} from "@src/components/Button/Button";
import {Success} from "@src/components/AllSvg/Success";
import {selectBackError} from "@src/store/ducks/auth/selectors";
import {selectUserInfo} from "@src/store/ducks/user/selectors";
import {FaC} from "react-icons/fa6";

// TRAIN FLOW: add vehicleType
type Step5Props = {
    vehicleType: string,
}


export const Step5: FC<Step5Props> = ({vehicleType}) => {
    const user = useSelector(selectUserInfo)
    const {getFieldProps} = useFormikContext();
    const chosenTour = useSelector(selectCurrentChoosenTourData)
    const backError = useSelector(selectBackError)
    const sourceRegistrationFromApi = useSelector(selectSourceRegirstration)
    const sourceRegistration = getFieldProps('data.sourceRegistration').value


    return (
        <div className={style['step-5']}>
            <div className={style['final-order']}>
                <div className={style.title}>
                    Итоговые данные заявки
                </div>
                <div className={style['final-order-titleBlock']}>
                    <div className={style['tour-date']}>
                        <Calendar/>
                        <span>{getFieldProps('data.startDate').value}</span> - <span>{getFieldProps('data.endDate').value}</span>
                    </div>
                    <div className={style['tour-title']}>
                        {chosenTour.tour.title}
                    </div>
                </div>
                <div className={style['final-order-list']}>
                    {getFieldProps('data.tourists').value.map((item: any, index: number) => (
                        // TRAIN FLOW: add vehicleType
                        <OrderElem vehicleType={vehicleType} key={index} data={item} index={index}/>
                    ))}
                </div>
                <div className={style.additional}>
                    <div className={style.title}>
                        Дополнительные сведения
                    </div>
                    <div className={style['additional-wrap']}>
                        <FieldFormik name='data.applicationDate' text={getFieldProps('data.applicationDate').value}
                                     typeInput='input-date'
                                     label='Дата заявки' svg={true}/>
                        <FieldFormik name='data.applicationDate' text={sourceRegistrationFromApi[sourceRegistration]}
                                     typeInput='input-date'
                                     label='Путь оформления' svg={false} className={style['input-payment']}/>
                        {user.roleId !== 3 &&
                            <FieldFormik name='data.applicationDate'
                                         text={`${+chosenTour.tour.commissionAmountChild} ${chosenTour.currency}`}
                                         typeInput='input-date'
                                         label='Комиссия партнера до 14 лет' svg={false}
                                         className={style['input-payment']}/>
                        }
                        {user.roleId !== 3 &&
                            <FieldFormik name='data.applicationDate'
                                         text={`${+chosenTour.tour.commissionAmountFull} ${chosenTour.currency}`}
                                         typeInput='input-date'
                                         label='Комиссия партнера после 14 лет' svg={false}
                                         className={style['input-payment']}/>
                        }
                    </div>
                    <TextAreaFormik name={'data.additionalExcursions'} label={'Дополнительные экскурсии'}
                                    placeholder={'Пример: Название экскурсии'} readonly={true}/>
                    <div className={style.paymentBlock}>
                        <div className={style['paymentBlock-title']}>
                            Данные об оплате
                        </div>
                        <div className={style['paymentBlock-wrapper']}>
                            <div className={style.firstBlock}>
                                <FieldFormik name='data.finalFirstPartPriceByn'
                                             text={getFieldProps('data.finalFirstPartPriceByn').value}
                                             typeInput='input-date'
                                             label='Стоимость услуги (Первый взнос), BYN'
                                             className={style['input-payment']}/>
                                {user.roleId === 2 &&
                                    <div className={style.message}>Сумма указана за вычетом комиссии</div>}
                            </div>
                            <FieldFormik name='data.finalSecondPartPriceByn'
                                         text={getFieldProps('data.finalSecondPartPriceByn').value}
                                         typeInput='input-date'
                                         label='Стоимость услуги (Второй взнос), BYN'
                                         className={style['input-payment']}/>
                            <FieldFormik name='data.applicationDate'
                                         text={getFieldProps('data.finalPriceUsd').value + " " + chosenTour.currency}
                                         typeInput='input-date'
                                         label='Стоимость тура' className={style['input-payment']}/>
                        </div>
                    </div>
                </div>
                {backError && String(backError) && (
                    <div className={style.error}>
                        {backError}
                    </div>
                )}
                <Button svgComponent={Success} htmlType={"submit"}
                        className={style.successBtn}>
                    Оформить заявку
                </Button>
            </div>
        </div>
    )
}


