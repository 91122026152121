import {Navigate, Route, Routes} from 'react-router-dom'

import {Login} from '@src/pages/Login/Login'
import {RecoveryPassword} from '@src/pages/RecoveryPassword/RecoveryPassword'
import {Tours} from '@src/pages/Tours/Tours'
import {History} from "@src/pages/History/History";
import {Error404} from '@src/pages/Errors/Error404'
import {PrivateRoute} from '@src/routes/PrivateRoute'
import {useSelector} from "react-redux";
import {selectIsAuth} from "@src/store/ducks/auth/selectors";
import {ConfirmPassword} from "@src/pages/ConfirmPassword/ConfirmPassword";
import {ChangePassword} from "@src/pages/Profile/ChangePassword/ChangePassword";
import {ChangeProfileInfo} from "@src/pages/Profile/ChangeProfileInfo/ChangeProfileInfo";
import {ProfileInfo} from "@src/pages/Profile/ProfileInfo/ProfileInfo";
import {NewPassword} from "@src/pages/NewPassword/NewPassword";

function RequireAuth({children, redirectTo}: any) {
    const isAuth = useSelector(selectIsAuth)
    return isAuth ? children : <Navigate to={redirectTo}/>;
}

export const RoutesProject = () => (
    <Routes>
         Редирект на логин
        <Route
            path="/"
            element={<Navigate to="/login" replace/>}
        />

        {/* Логин */}
        <Route path="/login" element={<Login/>}/>

        {/* Забыли пароль */}
        <Route path="/recovery-password" element={<RecoveryPassword/>}/>

        {/* Забыли пароль */}
        <Route path="/confirm-password" element={<ConfirmPassword/>}/>

        <Route
            path="/tours"
            element={
                <RequireAuth redirectTo='/login'>
                    <PrivateRoute Component={<Tours/>}/>
                </RequireAuth>
            }
        />
        <Route
            path="/history"
            element={
                <RequireAuth redirectTo='/login'>
                    <PrivateRoute Component={<History/>}/>
                </RequireAuth>
            }
        />
        <Route
            path="/profile"
            element={
                <RequireAuth redirectTo='/login'>
                    <PrivateRoute Component={<ProfileInfo/>}/>
                </RequireAuth>
            }
        />
        <Route
            path="/profileChange"
            element={
                <RequireAuth redirectTo='/login'>
                    <PrivateRoute Component={<ChangeProfileInfo/>}/>
                </RequireAuth>
            }
        />
        <Route
            path="/changePassword"
            element={
                <RequireAuth redirectTo='/login'>
                    <PrivateRoute Component={<ChangePassword/>}/>
                </RequireAuth>
            }
        />

        <Route path="/newPassword" element={<NewPassword/>}/>

        <Route
            path="*"
            element={
                <RequireAuth redirectTo='/login'>
                    <PrivateRoute Component={<Error404/>}/>
                </RequireAuth>
            }
        />
    </Routes>
)
