import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "@src/store/store";
import {logout} from "@src/store/ducks/auth/thunks";
import cn from 'classnames'
import {NavLink} from "react-router-dom";
import logo from '@src/images/logo.svg'
import {Button} from "@src/components/Button/Button";
import {TourSvg} from "@src/components/AllSvg/ToursSvg";
import {ProfileSvg} from "@src/components/AllSvg/ProfileSvg";
import {HistorySvg} from "@src/components/AllSvg/HistorySvg";
import {ExitSvg} from "@src/components/AllSvg/ExitSvg";

import style from '@src/components/Header/Header.module.scss'
import {selectUserInfo} from "@src/store/ducks/user/selectors";
import {setSuccessPage, setTourChoiseOpen, setTourFormalisationOpen} from "@src/store/ducks/app/reducer";
import {setPassengersQuantity, setStepsState} from "@src/store/ducks/orders/reducer";


export const Header = () => {
    const dispatch = useDispatch<AppDispatch>()
    const user = useSelector(selectUserInfo)
    return (
        <div className={style.header}>
            <div className={cn('container-header', style['container-header'])}>
                <div className={cn('header-inner', style['header-inner'])}>
                    <NavLink
                        to="/tours"
                        onClick={() => {
                            dispatch(setTourFormalisationOpen(false));
                            dispatch(setSuccessPage(false));
                            dispatch(setTourChoiseOpen(true));
                            dispatch(setPassengersQuantity(''))
                            dispatch(setStepsState())
                        }}>
                        <div className={style.logo}>
                            <img
                                src={logo}
                                alt="logo"
                            />
                        </div>
                    </NavLink>
                    <div className={style.tabs}>
                        <NavLink
                            className={({isActive}) => {
                                return isActive ? "btn active" : "btn";
                            }}
                            to="/tours"
                            onClick={() => {
                                dispatch(setPassengersQuantity(''));
                                dispatch(setStepsState())
                            }}
                        >
                            <TourSvg/>
                            <span>Таблица рейсов</span>
                        </NavLink>
                        <NavLink
                            className={({isActive}) => {
                                return isActive ? "btn active" : "btn";
                            }}
                            to="/history"
                            onClick={() => {
                                dispatch(setPassengersQuantity(''));
                                dispatch(setStepsState())
                            }}
                        >
                            <HistorySvg/>
                            <span>История</span>
                        </NavLink>
                        {user.roleId === 2 && <NavLink
                            className={({isActive}) => {
                                return isActive ? "btn active" : "btn";
                            }}
                            to="/profile"
                            onClick={() => {
                                dispatch(setPassengersQuantity(''));
                                dispatch(setStepsState())
                            }}
                        >
                            <ProfileSvg/>
                            <span>Профиль</span>
                        </NavLink>}

                    </div>
                    <div className={style.name}>
                        <span>{user.roleTitle}</span>
                        <span>{user.fio}</span>
                    </div>
                    <Button onClickFunc={() => {
                        dispatch(logout())
                    }} svgComponent={ExitSvg} className={style.exit}>
                        Выйти из аккаунта
                    </Button>
                </div>
            </div>
        </div>
    )
}
