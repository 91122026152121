import React, {FC, useState} from "react";
import cn from 'classnames'
import {Button} from "@src/components/Button/Button";
import style from "@src/pages/Tours/TourChoise/components/TableElem/TableElem.module.scss";
import {TripsResultArrElemType} from "@src/store/ducks/trips/types";
import {useDispatch} from "react-redux";
import {AppDispatch} from "@src/store/store";
import {setCurrentChoosenTourData} from "@src/store/ducks/orders/reducer";
import {setTourChoiseOpen, setTourFormalisationOpen} from "@src/store/ducks/app/reducer";
import {getBusPlacesAsinc} from "@src/store/ducks/orders/thunks";
import {Calendar} from "@src/components/AllSvg/Calendar";
import {Price} from "@src/components/AllSvg/Price"
import {Seat} from "@src/components/AllSvg/Seat"
import {TourRoute} from "@src/pages/Tours/TourChoise/components/TourRoute/TourRoute";


type TableElemProps = {
    tripOne: TripsResultArrElemType
    typePage?: string
};

export const TableElem: FC<TableElemProps> = ({
                                                  tripOne, typePage
                                              }) => {

    const dispatch = useDispatch<AppDispatch>()
    const [showRoute, setShowRoute] = useState(false)
    const trip = {
        title: tripOne.title,
        dateStart: tripOne.dateStart,
        dateEnd: tripOne.dateEnd,
        duration: tripOne.tour.days,
        price: tripOne.price,
        additionalPrice: tripOne.additionalPrice,
        commissionAmountChild: tripOne.tour.commissionAmountChild,
        commissionAmountFull: tripOne.tour.commissionAmountFull,
        commissionAmountPlace: tripOne.tour.commissionAmountPlace,
        comissionAmountPartner : tripOne.tour.commissionAmountPartner,
        places: tripOne.places,
        descriptionLink:tripOne.tour.descriptionLink,
        routes:tripOne.parsedRoutes,
        freePlaces:tripOne.freePlaces,
        currency:tripOne.currency
    }

    function changeCurrentChoisenTourData(data: TripsResultArrElemType) {
        dispatch(setCurrentChoosenTourData(data))
        dispatch(setTourChoiseOpen(false))
        dispatch(setTourFormalisationOpen(true))
        dispatch(getBusPlacesAsinc(tripOne.id))
    }

    return (
        <div className={cn(style.TableElem)}>
            <div className={cn(style['TableElem-block'], style.title)}>
                <label>Название тура</label>
                <a href={trip.descriptionLink} target='_blank' className={cn(style['TableElem-flex'], style['first-elem'])}>{trip.title}</a>
            </div>
            <div className={cn(style['TableElem-block'], style['custom-text'], style.date)}>
                {typePage === 'tours' ? <label>Дата выезда тура</label> : <label>Дата проведения тура</label>}
                <div className={style['TableElem-flex']}>
                    <Calendar/>
                   <span>{trip.dateStart} - {trip.dateEnd}</span>
                </div>
            </div>
            <div className={cn(style['TableElem-block'], style['custom-text'], style.duration)}>
                <label>Количество дней</label>
                <div className={style['TableElem-flex']}>
                    <Calendar/>
                    <span>{trip.duration}</span>
                </div>
            </div>
            <div className={cn(style['TableElem-block'], style['custom-text'], style.price)}>
                <label>Цена</label>
                <div className={style['TableElem-flex']}>
                    <Price/>
                    <span>{+trip.price} {trip.currency} + {+trip.additionalPrice} byn </span>
                </div>
            </div>
            <div className={cn(style['TableElem-block'], style.route)}>
                <label>Маршрут тура</label>
                <div className={style['TableElem-flex']}>
                    <div className={cn(style.link, 'buttonClick')} onClick={() => setShowRoute(true)}>
                        Показать маршрут
                    </div>
                </div>
            </div>
            <div className={cn(style['TableElem-block'], style.commission)}>
                <label>Комиссия (byn)</label>
                <div className={style['TableElem-flex']}>{trip.comissionAmountPartner? `${+trip.comissionAmountPartner} byn`  : 'Нет'}</div>
            </div>
            <div className={cn(style['TableElem-block'], style.commission)}>
                <label>Комиссия</label>
                <div className={style['TableElem-flex']}>{+trip.commissionAmountFull} {trip.currency} </div>
            </div>
            <div className={cn(style['TableElem-block'], style['custom-text'], style.seats)}>
                <label>Осталось мест</label>
                <div className={style['TableElem-flex']}>
                    <Seat/>
                    <span>{trip.freePlaces}</span>
                </div>
            </div>
            <div className={cn(style['TableElem-block'], style['TableElem-block-btn'])}>
                <label>{'title'}</label>
                <div className={style['TableElem-btn']}>
                    <Button onClickFunc={() => changeCurrentChoisenTourData(tripOne)}
                            className={cn('btn', 'btn-book', trip.freePlaces === 0? 'btn-disabled' : '' )} disabled={ trip.freePlaces === 0 }>Бронировать</Button>
                </div>
            </div>
            {showRoute && <TourRoute routes={trip.routes} showRoute={showRoute} setShowRoute={setShowRoute}/>}
        </div>
    );
};
