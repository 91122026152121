import React, {FC} from "react";
import style from "@src/pages/History/HistoryDetails/HistoryDetails.module.scss"
import cn from "classnames";
import {Calendar} from "@src/components/AllSvg/Calendar";


type FieldHistoryProps = {
    svgComponent?: React.ComponentType<unknown>,
    text?: string,
    label?: string,
    svg?: boolean,
    className?: string
    type?:string
    currency?:string
}

export const FieldHistory: FC<FieldHistoryProps> = ({svgComponent: SvgComponent, text, label, svg, className, type, currency}) => {
    return (
        <div className={cn(style.fieldHistory)}>
            <label>
                {label && <div className={style.label}>{label}</div>}
                <div className={cn(style.fieldHistoryWrap, className)}>
                    {svg && <Calendar/>}
                    <div>{(type === 'number' && text && currency) ? +text + ' ' + currency  : (type === 'number' && text) ? +text + ' byn' : text}</div>
                </div>
            </label>
        </div>
    )
}


