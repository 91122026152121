import React from "react";


export const Clear = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
            <path d="M2.2915 9.99999C2.2915 14.6023 6.02246 18.3333 10.6248 18.3333C15.2272 18.3333 18.9582 14.6023 18.9582 9.99999C18.9582 5.39761 15.2272 1.66666 10.6248 1.66666" stroke="#DB0000" strokeWidth="1.44" strokeLinecap="round"/>
            <path d="M4.49896 4.23231C4.61425 4.1101 4.73306 3.99126 4.85525 3.87594M7.24794 2.2745C7.3992 2.20651 7.55277 2.14274 7.7085 2.08334M2.9016 6.61828C2.83287 6.77099 2.76845 6.92607 2.7085 7.08334" stroke="#DB0000" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.125 7.5L10.625 10M10.625 10L8.125 12.5M10.625 10L13.125 12.5M10.625 10L8.125 7.5" stroke="#DB0000" strokeWidth="1.44" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}