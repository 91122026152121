import style from '@src/components/ButtonTop/ButtonTop.module.scss'
import {ArrowTop} from "@src/components/AllSvg/ArrowTop";

export const ButtonTop = () => {
    const handleClick = () => {
        document.body.scrollTop = 0;
    }

    return (
        <div className={style.buttonTop} onClick={handleClick}>
            <div className={style.arrow}>
                <ArrowTop/>
            </div>
        </div>
    )
}
