import React from "react";

export const ArrowTop = () => {

    return (
        <svg width="100%" height="100%" viewBox="0 0 15.8999 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs/>
            <path id="Vector" d="M1.0498 9L0 7.94995L7.9502 0L15.8999 7.94995L14.8501 9L7.9502 2.10004L1.0498 9Z"
                  fill="#FFFFFF" fillOpacity="1.000000" fillRule="evenodd"/>
        </svg>
    )
}