import React, {FC, useCallback, useMemo, useState,} from 'react'
import {useField} from 'formik'
import cn from 'classnames'

import style from '@src/components/common/TextAreaFormik/TextAreaFormik.module.scss'

type TextAreaFormikProps = {
    name: string
    placeholder?: string
    disabled?: boolean
    className?: string
    label?: string
    readonly?:boolean
}

export const TextAreaFormik: FC<TextAreaFormikProps> = ({
                                                            className, name, placeholder, label,readonly
                                                        }) => {
    const [field, meta] = useField(name)
    const [isFocused, setIsFocused] = useState<boolean>(false)
    const isError = useMemo(() => !isFocused && meta.error && meta.touched, [isFocused, meta.error, meta.touched])

    const handleFocus = useCallback(() => {
        setIsFocused(true)
    }, [])
    const handleBlur = useCallback((e: React.FormEvent<HTMLTextAreaElement>) => {
        setIsFocused(false)
        field.onBlur(e)
    }, [field])

    return (
        <label className={cn(style.wrapper, className)}>
            {label &&
                <div className={style.label}>{label}</div>}

            <textarea
                {...field}
                className={cn(style.textArea, isError && style.textArea_error, className, ` ${field.value ? style['textArea-fill'] : ''}`)}
                placeholder={placeholder}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value = {field.value}
                readOnly={readonly}
            />
            {isError && <div className={style.error}>{meta.error}</div>}

        </label>
    )
}
