import React, {FC, ReactNode} from 'react'
import cn from "classnames";
import style from '@src/components/Button/Button.module.scss'
import arrowRight from '@src/images/arrow-right.svg'

type ButtonProps = {
    htmlType?: 'submit' | 'reset' | 'button'
    className?: string
    type?: 'primary' | 'secondary'
    disabled?: boolean
    onClickFunc?: any
    loading?: boolean
    children?: ReactNode | string,
    svgComponent?: React.ComponentType<unknown>
    step?:string,
    arrow?: boolean
}

export const Button: FC<ButtonProps> = ({
                                            onClickFunc,
                                            children,
                                            htmlType,
                                            className,
                                            disabled,
                                            svgComponent: SvgComponent,step,
    arrow
                                        }) => (
    <button
        // eslint-disable-next-line react/button-has-type
        type={htmlType ?? 'button'}
        className={cn(style.btn , className)}
        disabled={disabled}
        onClick={onClickFunc}
    >
        <div className={'button-container'}>
            {step &&  <div className={'numStep'}>{step}</div>}
            {SvgComponent && <SvgComponent/>}
            {children}
            {arrow && <img src={arrowRight} alt='arrowRight'/>}
        </div>
    </button>
)
