import {OrdersApi} from "@src/api/orders-api";
import {AppDispatch} from '@src/store/store'
import {setCurrentBusPlaces, setHistoryData, setOrderId, setOredrsParams} from "@src/store/ducks/orders/reducer";
import {setSuccessPage, setTourFormalisationOpen} from "@src/store/ducks/app/reducer";
import {setBackError} from "@src/store/ducks/auth/reducer";
import {keysToCamelCase} from "@src/lib/utils";


export const getBusPlacesAsinc = (id:number) => async (dispatch: AppDispatch) => {
  try {
    const result = await OrdersApi.getBusPlaces(id)
    dispatch(setCurrentBusPlaces(result))
  } catch (err: any) {
    if (err?.response?.status === 401) {
    }
  } finally {
  }
}

export const getParamsAsinc = () => async (dispatch: AppDispatch) => {
  try {
    const result = await OrdersApi.getParams()
    dispatch(setOredrsParams(result))
  } catch (err: any) {
    if (err?.response?.status === 401) {
    }
  } finally {
  }
}

export const getHistoryAsinc = (status:number| null,id:number|null ) => async (dispatch: AppDispatch) => {
  try {
    const result = await OrdersApi.getHistory(status,id)
    dispatch(setHistoryData(result))
  } catch (err: any) {
    if (err?.response?.status === 401) {
    }
  } finally {
    /*    dispatch(setIsInitialized())*/
  }
}

export const postOrder = (data:any) => async (dispatch: AppDispatch) => {
  const result = await OrdersApi.postOrder(data)
  try {
    dispatch(setOrderId(result.result.orderId))
    dispatch(setTourFormalisationOpen(false));
    dispatch(setSuccessPage(true));
    dispatch(setBackError(null));
  } catch (err: any) {
    dispatch(setBackError(keysToCamelCase(result[0])))
  } finally {

  }
}

export const getPdfActAsinc = (id:number) => async (dispatch: AppDispatch) => {
  try {
    const result = await OrdersApi.getPdfAct(id)
    const url = result.file;

    const link = document.createElement('a');
    link.href = url;
    link.target = "_blank";
    link.download = 'document.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  } catch (err: any) {
    if (err?.response?.status === 401) {
    }
  } finally {
    /*    dispatch(setIsInitialized())*/
  }
}

export const getPdfReportAsinc = (id:number) => async (dispatch: AppDispatch) => {
  try {
    const result = await OrdersApi.getPdfReport(id)

    const url = result.file;
    const link = document.createElement('a');
    link.href = url;
    link.target = "_blank";
    link.download = 'document.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  } catch (err: any) {
    if (err?.response?.status === 401) {
    }
  } finally {
  }
}

