import React from "react";
import cn from "classnames";
import {UserType} from "@src/store/ducks/user/types";

import style from '@src/pages/Profile/ProfileInfo/ProfileInfo.module.scss'
import {Title} from "@src/components/Title/Title";
import {ProfileSvg} from "@src/components/AllSvg/ProfileSvg";
import {Button} from "@src/components/Button/Button";
import {useSelector} from "react-redux";
import {selectUserInfo} from "@src/store/ducks/user/selectors";
import {NavLink} from "react-router-dom";


export const ProfileInfo = () => {
    const data: UserType = useSelector(selectUserInfo)
    const user= {
        id: data.id,
        email: data.email,
        createdAt: '',
        usedPlaces: data.usedPlaces,
        companyName: data.companyName,
        unp: data.unp,
        legalAddress: data.legalAddress,
        bankingDetails: data.bankingDetails,
        sertificateDetails: data.sertificateDetails,
        passportExpiryDate: data.passportExpiryDate,
        passportSeriesAndNumber: data.passportSeriesAndNumber,
        passportIssuedBy: data.passportIssuedBy,
        passportPersonalNumber: data.passportPersonalNumber,
        birthDateIp: data.birthDateIp,
        phoneNumberIp: data.phoneNumberIp,
        residenceAddressIp: data.residenceAddressIp,
        urCompanyName: data.urCompanyName,
        urSertificateDetails: data.urSertificateDetails,
        urFio: data.urFio,
        urBank: data.urBank,
        urBik: data.urBik,
        urPaymentNumber: data.urPaymentNumber,
        urUnp: data.urUnp,
        urOkpo: data.urOkpo,
        urEmail: data.urEmail,
        urPhone: data.urPhone,
        urAddress: data.urAddress,
        type: data.type,
        certificate: data.certificate,
        bankingDetailsNumber:data.bankingDetailsNumber,
        emailIp:data.emailIp
    }

    let formattedDate = ''
    if (data.createdAt) {
        const dateString = data.createdAt;
        const date = new Date(dateString);
        formattedDate = date.toLocaleDateString('ru-RU', {day: 'numeric', month: 'numeric', year: 'numeric'});
        user.createdAt = formattedDate
    }
    return (
        <div className={style['profile-info-wrapper']}>
            <Title title={'Профиль'} svgComponent={ProfileSvg}></Title>
            <div className={style.profileInfo}>
                <div className={style.left}>
                    {user.certificate ? <img src={user.certificate} alt="image"/> :
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" alt='image'/>}
                </div>
                {user.type === 1 &&
                    <div className={style.right}>
                        <div className={cn(style['main-title'])}>
                            {user.companyName}
                        </div>
                        <div className={cn(style['custom-block'], style.date)}>
                            <div className={style.subtitle}>Дата регистрации аккаунта:</div>
                            <div className={cn(style.subtitle)}>{user.createdAt}</div>
                        </div>
                        <div className={style.custom}>
                            <div className={style.subtitle}>Свидетельство о государственной регистрации</div>
                            <div className={cn(style['strong-text'])}>{user.sertificateDetails}</div>
                        </div>
                        <div className={style.custom}>
                            <div className={style.subtitle}>Банковские реквизиты</div>
                            <span className={cn(style['ordinary-text'])}>Р/С: </span><span
                            className={cn(style['strong-text'])}>{user.bankingDetailsNumber}</span>
                            <div className={cn(style['ordinary-text'])}>{user.bankingDetails}</div>
                        </div>
                        <div className={style.custom}>
                            <div className={style.subtitle}>E-mail</div>
                            <div className={cn(style['strong-text'])}>{user.emailIp}</div>
                        </div>

                        <div className={style.custom}>
                            <div className={style.subtitle}>Телефон для связи</div>
                            <div className={cn(style['strong-text'])}>{user.phoneNumberIp}</div>
                        </div>
                        <div className={style.custom}>
                            <div className={style.subtitle}>Адрес прописки</div>
                            <div className={cn(style['ordinary-text'])}>{user.residenceAddressIp}</div>
                        </div>
                    </div>
                }
                {user.type === 2 &&
                    <div className={style.right}>
                        <div className={cn(style['main-title'])}>
                            {user.urCompanyName}
                        </div>
                        <div className={cn(style['custom-block'], style.date)}>
                            <div className={style.subtitle}>Дата регистрации аккаунта:</div>
                            <div className={cn(style.subtitle)}>{user.createdAt}</div>
                        </div>
                        <div className={style['custom-block']}>
                            <div className={style.subtitle}>ФИО Владельца аккаунта</div>
                            <div className={cn(style['strong-text'])}>{user.urFio}</div>
                        </div>
                        <div className={style.custom}>
                            <div className={style.subtitle}>Свидетельство о государственной регистрации</div>
                            <div className={cn(style['strong-text'])}>{user.urSertificateDetails}</div>
                        </div>
                        <div className={style.custom}>
                            <div className={style.subtitle}>Банковские реквизиты</div>
                            <span className={cn(style['ordinary-text'])}>Р/С: </span><span
                            className={cn(style['strong-text'])}>{user.urPaymentNumber}</span>
                            <div className={cn(style['ordinary-text'])}>{user.urBank}</div>
                        </div>
                        <div className={style.custom}>
                            <div className={style.subtitle}>УНП</div>
                            <div className={cn(style['strong-text'])}>{user.urUnp}</div>
                        </div>
                        <div className={style.custom}>
                            <div className={style.subtitle}>BIC</div>
                            <div className={cn(style['ordinary-text'])}>{user.urBik}</div>
                        </div>
                        <div className={style.custom}>
                            <div className={style.subtitle}>ОКПО</div>
                            <div className={cn(style['ordinary-text'])}>{user.urOkpo}</div>
                        </div>

                        <div className={style.custom}>
                            <div className={style.subtitle}>E-mail</div>
                            <div className={cn(style['strong-text'])}>{user.urEmail}</div>
                        </div>
                        <div className={style.custom}>
                            <div className={style.subtitle}>Телефон для связи</div>
                            <div className={cn(style['strong-text'])}>{user.urPhone}</div>
                        </div>
                        <div className={style.custom}>
                            <div className={style.subtitle}>Юридический адрес</div>
                            <div className={cn(style['ordinary-text'])}>{user.urAddress}</div>
                        </div>
                    </div>
                }
            </div>
            <div className={style['buttons-block']}>
                <NavLink
                    to="/profileChange"
                >
                    <Button onClickFunc={() => {
                    }} className={'btn-change-data'}>Редактировать данные</Button>
                </NavLink>
                <NavLink
                    to="/changePassword"
                >
                    <Button onClickFunc={() => {
                    }} className={'btn-change-password'}>Изменить пароль</Button>
                </NavLink>
            </div>
        </div>
    )
}
