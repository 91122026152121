import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {TripsResultArrElemType} from "@src/store/ducks/trips/types";
import {OrdersHistoryType, OrdersParamsType} from "@src/store/ducks/orders/types";


export const initialState = {
    passengersQuantity: {} as any  || '' ,
    currentChoosenTourData: {} as TripsResultArrElemType,
    currentBusPlaces: [],
    currentChoosenBusPlaces: [],
    ordersParams: {} as OrdersParamsType,
    stepsState: {
        step1: false,
        step2: true,
        step3: true,
        step4: true,
        step5: true,
    },
    historyData: {} as OrdersHistoryType,
    orderId:0
}

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setPassengersQuantity(state, {payload}: PayloadAction<any>) {
            state.passengersQuantity = payload
        },
        setCurrentChoosenTourData(state, {payload}: PayloadAction<TripsResultArrElemType>) {
            state.currentChoosenTourData = payload
        },
        setCurrentBusPlaces(state, {payload}: PayloadAction<any>) {
            state.currentBusPlaces = payload
        },
        setCurrentChoosenBusPlaces(state, {payload}: PayloadAction<any>) {
            state.currentChoosenBusPlaces = payload
        },
        setOredrsParams(state, {payload}: PayloadAction<OrdersParamsType>) {
            state.ordersParams = payload
        },
        setStepsState(state) {
            state.stepsState = initialState.stepsState;
        },
        setStepsState1(state, {payload}: PayloadAction<boolean>) {
            state.stepsState.step1 = payload
        },
        setStepsState2(state, {payload}: PayloadAction<boolean>) {
            state.stepsState.step2 = payload
        },
        setStepsState3(state, {payload}: PayloadAction<boolean>) {
            state.stepsState.step3 = payload
        },
        setStepsState4(state, {payload}: PayloadAction<boolean>) {
            state.stepsState.step4 = payload
        },
        setStepsState5(state, {payload}: PayloadAction<boolean>) {
            state.stepsState.step5 = payload
        },

        setHistoryData(state, {payload}: PayloadAction<any>) {
            state.historyData = payload
        },
        setOrderId(state, {payload}: PayloadAction<any>) {
            state.orderId = payload
        },
    },
})

export type OrdersStateType = typeof initialState

export const {
    setPassengersQuantity,
    setCurrentChoosenTourData,
    setCurrentBusPlaces,
    setCurrentChoosenBusPlaces,
    setOredrsParams,
    setStepsState,
    setStepsState1,
    setStepsState2,
    setStepsState3,
    setStepsState4,
    setStepsState5,
    setHistoryData,
    setOrderId
} = ordersSlice.actions
export const ordersReducer = ordersSlice.reducer
