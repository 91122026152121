import {RootState} from '@src/store/store'
import {OrdersStateType} from '@src/store/ducks/orders/reducer'
import {RouteElem} from "@src/store/ducks/trips/types";

export const selectOrders = (state: RootState): OrdersStateType => state.orders

export const selectOrdersPassengersQuantity = (state: RootState) => selectOrders(state).passengersQuantity
export const selectCurrentChoosenTourData = (state: RootState) => selectOrders(state).currentChoosenTourData

export const selectCityTour = (state: RootState) => selectOrders(state).currentChoosenTourData.parsedRoutes

export const selectCurrentBusPlaces = (state: RootState) => selectOrders(state).currentBusPlaces
export const selectCurrentChoosenBusPlaces = (state: RootState) => selectOrders(state).currentChoosenBusPlaces

export const selectRoomTypes = (state: RootState) => selectOrders(state).ordersParams.roomTypes
export const selectCitizenCategory = (state: RootState) => selectOrders(state).ordersParams.citizenCategory
export const selectGender = (state: RootState) => selectOrders(state).ordersParams.gender

export const selectOrderStatuses = (state: RootState) => selectOrders(state).ordersParams.orderStatuses
export const selectSourceRegirstration = (state: RootState) => selectOrders(state).ordersParams.sourceRegirstration

export const selectCurrentFreePlaces = (state: RootState) => selectOrders(state).currentChoosenTourData.freePlaces

export const selectSteps = (state: RootState) => selectOrders(state).stepsState

export const selectHistoryOrdersDate = (state: RootState) => selectOrders(state).historyData.orders?.data

export const selectOrderId = (state: RootState) => selectOrders(state).orderId
