import {FC, useMemo} from "react";
import {Form, Formik} from "formik";
import * as Yup from 'yup'

import {NavLink} from "react-router-dom";
import {FieldFormik} from '@src/components/common/FieldFormik/FieldFormik'
import {Button} from "@src/components/Button/Button";
import {validateForm} from '@src/messages/validateForm'
import {emailRegexp} from '@src/lib/regex'

import style from '@src/pages/Login/Login.module.scss'
import {AppDispatch} from "@src/store/store";

import {login} from "@src/store/ducks/auth/thunks";
import {useDispatch, useSelector} from "react-redux";
import {selectBackError} from "@src/store/ducks/auth/selectors";

type LoginFormProps = {
  isLoading: boolean
}

export const LoginForm: FC<LoginFormProps> = ({ isLoading }) => {
  const dispatch = useDispatch<AppDispatch>()
    const backError = useSelector(selectBackError)

    const validationSchema = useMemo(() => (
        Yup.object().shape({
            emailUser: Yup.string().matches(emailRegexp, validateForm.email).required(validateForm.required),
            password: Yup.string().min(8, 'Состоит из 8 символов')
                .max(9, 'Состоит из 8 символов').required(validateForm.required),
        })
    ), [])

  const initialValues = useMemo(() => ({
    emailUser: '',
    password: '',
  }), [])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({ emailUser, password }) => {
        dispatch(login(emailUser, password))
      }}
      validationSchema={validationSchema}
    >
      {() => (
        <Form>
          <div>
              <FieldFormik
                  name="emailUser"
                  placeholder='mymail@gmail.com'
                  label='Электронная почта'
                  typeInput='input-usual'
              />
              <FieldFormik
                  name="password"
                  type="password"
                  placeholder="Состоит из 8 символов"
                  label="Пароль"
                  typeInput='input-usual'
                  pattern="\d{8}"
              />
          </div>
            {backError && String(backError) && (
                <div className={style.error}>
                    {backError}
                </div>
            )}
          <div className={style.btnBlock}>
            <NavLink
              to="/recovery-password"
              className={`${style.forgotBtn}`}
            >
              Забыли пароль?
            </NavLink>
              <Button
                  loading={isLoading}
                  htmlType="submit"
                  type="secondary"
                  className={'btn-second'}
              >
                  Выполнить вход
              </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
