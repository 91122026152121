import cn from 'classnames'
import recoveryBg from '@src/images/recovery-bg.jpg'
import {TitleLogin} from "@src/components/TitleLogin/TitleLogin";
import style from '@src/pages/ConfirmPassword/ConfirmPassword.module.scss'
import React from "react";
import {NewPasswordForm} from "@src/pages/NewPassword/NewPasswordForm";


export const NewPassword = () => {
    return (
        <div className={cn(style.confirm)} >
            <div className={cn(style['confirm-left'])}>
                <img
                    src={recoveryBg}
                    alt="recoveryBg"
                />
            </div>
            <div className={cn(style['confirm-right'])}>
                <div className={cn(style['confirm-right-body'])}>
                    <TitleLogin title={'Введите новый пароль'} className={style.titleLogin}/>
                    <NewPasswordForm/>
                </div>
            </div>
        </div>
    )
}
