import React, {useEffect, useState} from 'react';
import {TableElem} from "@src/pages/Tours/TourChoise/components/TableElem/TableElem";

import {useSelector} from "react-redux";
import {selectTripsResultArr, selectTripsResultHasMore} from "@src/store/ducks/trips/selectors";
import {TripsResultArrElemType} from "@src/store/ducks/trips/types";
import style from "@src/pages/Tours/TourChoise/components/TourTable/TourTable.module.scss"
import {ButtonTop} from "@src/components/ButtonTop/ButtonTop";

export const TourTable = () => {
    const tripsArr = useSelector(selectTripsResultArr)
    const [showButtonTop, setShowButtonTop] = useState(false)

    const hasMore = useSelector(selectTripsResultHasMore)

    useEffect(() => {
        if (hasMore) {
            setShowButtonTop(false);
        }
    }, [hasMore])

    useEffect(() => {
        document.body.addEventListener('scroll', () => scrollHandler());

        return function () {
            document.body.removeEventListener('scroll', () => scrollHandler());
        }
    }, []);

    const scrollHandler = () => {
        let scrollTop = document.body.scrollTop;
        if(scrollTop > 0) {
            setShowButtonTop(true);
        }
    }

    return (
        <div className={style.resultTable}>
            {tripsArr.map((item: TripsResultArrElemType) => (
                <TableElem key={item.id} tripOne={item} typePage='tours'/>))}
            {showButtonTop && <ButtonTop/>}
        </div>
    );
};

