import {CheckBoxFormik} from "@src/components/common/CheckBoxFormik/CheckBoxFormik";
import style from '@src/pages/Tours/TourFormalization/components/steps/Step3/Step3.module.scss'
import {Button} from "@src/components/Button/Button";
import React, {FC, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import cn from 'classnames'
import {
    selectCurrentBusPlaces,
    selectCurrentChoosenTourData,
    selectCurrentFreePlaces,
    selectOrdersPassengersQuantity,
} from "@src/store/ducks/orders/selectors";
import {AppDispatch} from "@src/store/store";
import {setStepsState4} from "@src/store/ducks/orders/reducer";
import {useFormikContext} from "formik";
import {Place} from "@src/components/AllSvg/Place";
import {Bus49} from "@src/components/AllSvg/Buses/Bus49";
import {Bus51} from "@src/components/AllSvg/Buses/Bus51";
import {Bus53} from "@src/components/AllSvg/Buses/Bus53";

type Step3Props = {
    step?: any
    setStep?: any,
    name: string
}

let arrPlaces: string[] = [];
export const Step3: FC<Step3Props> = ({step, setStep, name}) => {
    const svgRef = useRef<any>(null);
    const {getFieldProps, setFieldValue} = useFormikContext();
    const currentFreePlaces = useSelector(selectCurrentFreePlaces)
    const dispatch = useDispatch<AppDispatch>()
    const placesData = useSelector(selectCurrentBusPlaces)
    const placesAmount = useSelector(selectOrdersPassengersQuantity)
    const currentChoosenTourData = useSelector(selectCurrentChoosenTourData)
    const chosePlace = getFieldProps('data.useFreePlaces').value;
    const scrollRef = useRef<any>();
    const [scrollValue, setScrollValue] = useState(0);
    const [maxValue, setMaxValue] = useState(100); // Начальное значение max
    const [firstShow, setFirstShow] = useState(false);

    const elem = getFieldProps('data.selectedPlaces').value

    useEffect(() => {
        const allPlaces = svgRef.current.querySelectorAll('.placeBus');
        allPlaces.forEach((element: any) => {
            element.classList.remove(style.choosen)
            const index = elem.indexOf(element.id);
            if (index > -1) {
                element.classList.add(style.choosen)
            }
        })
    }, [elem])


    useEffect(() => {
        arrPlaces = []
    }, [currentChoosenTourData])


    useEffect(() => {
        if (scrollRef.current) {
            setMaxValue(scrollRef.current.scrollWidth - scrollRef.current.clientWidth)
        }
        scrollRef.current.scrollLeft = scrollValue

    }, [scrollValue])


    const handleScroll = (e: any) => {
        setScrollValue(e.target.value)
    }

    const handleScrollImageWrapper = () => {
        if (scrollRef.current) {
            const scrollValueCurrent = scrollRef.current.scrollLeft;
            setScrollValue(scrollValueCurrent)
            const scrollValueMax = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
            setMaxValue(scrollValueMax)
        }
    }

    const changeStatus = (e: any) => {
        const activeElem = e.target.closest('g')
        if (!activeElem.classList.contains('busy') && !activeElem.classList.contains('system')) {
            let index = arrPlaces.indexOf(activeElem.id)
            if (index >= 0) {
                arrPlaces.splice(index, 1);
                activeElem.classList.remove(style.choosen)
            } else if (arrPlaces.length < +placesAmount.value) {
                arrPlaces.push(String(activeElem.id));
                activeElem.classList.add(style.choosen)
            }
        }
        setFieldValue(name, arrPlaces)
    }

    function randomArrayFilling() {
        if (svgRef.current) {
            const allPlaces = svgRef.current.querySelectorAll('.placeBus');
            allPlaces.forEach((item: any) => {
                if (arrPlaces.length < +placesAmount.value) {
                    let index = arrPlaces.indexOf(item.id)
                    if (index < 0 && item.classList.contains(style.free)) {
                        arrPlaces.push(item.id)
                    }
                }
            })
        }
        setFieldValue(name, arrPlaces)
    }

    useEffect(() => {
        if (!firstShow) {
            setFirstShow(true);
        } else {
            if (chosePlace) {
                setFieldValue(name, [])
                arrPlaces = []
                randomArrayFilling()
            } else {
                // Другие действия
            }
        }

        if (svgRef.current) {
            const allPlaces = svgRef.current.querySelectorAll('.placeBus');
            allPlaces.forEach((element: any) => {
                if (Object.keys(placesData).includes(element.id)) {
                    if (placesData[element.id] === 'system') {
                        element.classList.add(style.system)
                    } else if (placesData[element.id] === true) {
                        element.classList.add(style.free)
                    } else {
                        element.classList.add(style.busy)
                    }
                }
            });
        }
    }, [chosePlace]);


    return (
        <div className={style['step-3']}>
            <div className={style.title}>
                <span>Доступно мест</span>
                <span>{currentFreePlaces}</span>
            </div>
            <div className={style.subtitle}>Услуга выбора места, является платной и
                составляет {+currentChoosenTourData.tour.commissionAmountPlace} белорусских рублей.
            </div>
            <CheckBoxFormik
                name="data.useFreePlaces"
                type="checkbox"
                checked={chosePlace}
                label={(
                    <span>
                    Без выбора места
                </span>
                )}
            />
            <div className={style.scheme}>
                {chosePlace && <div className={style.mask}></div>}
                <div className={style.schemeWrapper}>
                    <div ref={scrollRef} className={style.imageWrapper} onScroll={handleScrollImageWrapper}>
                        <div className={style.imageBus}>
                            {currentChoosenTourData.bus.type === '1' &&
                                <Bus49 ref={svgRef} changeStatus={changeStatus}/>}
                            {currentChoosenTourData.bus.type === '2' &&
                                <Bus51 ref={svgRef} changeStatus={changeStatus}/>}
                            {currentChoosenTourData.bus.type === '3' &&
                                <Bus53 ref={svgRef} changeStatus={changeStatus}/>}
                        </div>
                    </div>
                    <div className={style.slidecontainer}>
                        <input type="range"
                               min='0'
                               max={maxValue}
                               value={scrollValue} onChange={handleScroll}/>
                    </div>
                </div>
                <div className={style.customPlaces}>
                    <div className={style['place-wrapper']}>
                        <div className={cn(style.place, style.colorWhite)}>
                            <Place/>
                        </div>
                        <span>Место свободно</span>
                    </div>
                    <div className={style['place-wrapper']}>
                        <div className={cn(style.place, style.colorRed)}>
                            <Place/>
                        </div>
                        <span>Место занято</span>
                    </div>
                    <div className={style['place-wrapper']}>
                        <div className={cn(style.place, style.colorGray)}>
                            <Place/>
                        </div>
                        <span>Служебное место</span>
                    </div>
                    <div className={style['place-wrapper']}>
                        <div className={cn(style.place, style.colorBlue)}>
                            <Place/>
                        </div>
                        <span>Место выбрано вами</span>
                    </div>
                </div>
            </div>
            <div className={style.info}>
                <p>Типовая схема* расположения мест в автобусе</p>
                <p>*Расположение кресел и дверей в автобусе указано примерно и может отличаться в зависимости от модели,
                    марки автобуса и общего количества посадочных мест.</p>
            </div>
            <Button onClickFunc={() => {
                (chosePlace && randomArrayFilling())
                setStep(step + 1);
                dispatch(setStepsState4(false))
            }}
                    className={'btn-second'}>
                Следующий шаг
            </Button>
        </div>
    )
}
