import React from "react";

export const DecorSvg = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 36 36" fill="" xmlns="http://www.w3.org/2000/svg">
            <g id="ticket-03">
                <path id="Vector"
                      d="M32.9994 13.3184C32.899 11.0053 32.6178 9.49947 31.8298 8.30826C31.3765 7.62294 30.8136 7.02688 30.1662 6.54702C28.4164 5.25 25.9479 5.25 21.0111 5.25H14.989C10.052 5.25 7.58359 5.25 5.83381 6.54702C5.18645 7.02688 4.62338 7.62294 4.17008 8.30826C3.38227 9.49933 3.10102 11.005 3.00062 13.3176C2.98345 13.7131 3.3241 14.0156 3.69734 14.0156C5.77601 14.0156 7.46111 15.7995 7.46111 18C7.46111 20.2005 5.77601 21.9843 3.69734 21.9843C3.3241 21.9843 2.98345 22.2869 3.00062 22.6824C3.10102 24.9951 3.38227 26.5007 4.17008 27.6918C4.62338 28.377 5.18645 28.9731 5.83381 29.4529C7.58359 30.75 10.052 30.75 14.989 30.75H21.0111C25.9479 30.75 28.4164 30.75 30.1662 29.4529C30.8136 28.9731 31.3765 28.377 31.8298 27.6918C32.6178 26.5005 32.899 24.9947 32.9994 22.6817V13.3184Z"
                      stroke="#0062AA" strokeWidth="1.5" strokeLinejoin="round"/>
                <path id="Vector_2" d="M19.5 18H25.5" stroke="#0062AA" strokeWidth="1.5" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path id="Vector_3" d="M13.5 24H25.5" stroke="#0062AA" strokeWidth="1.5" strokeLinecap="round"
                      strokeLinejoin="round"/>
            </g>
        </svg>

    )
}