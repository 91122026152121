import React from "react";


export const ExitSvg = () => {
return (
    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="log-out 1">
            <g id="Group">
                <path id="Vector" d="M19.2499 15.631L21.6599 12.526C21.8006 12.3448 21.8764 12.1215 21.8749 11.8921C21.8735 11.6627 21.7949 11.4404 21.6519 11.261L19.3169 8.335M21.4809 11.89L9.11793 11.906M15.2149 19.694C13.8536 20.4221 12.3271 20.7858 10.7837 20.7498C9.2403 20.7138 7.73243 20.2793 6.40653 19.4885C5.08062 18.6977 3.98173 17.5774 3.21657 16.2366C2.45141 14.8957 2.04597 13.3798 2.03963 11.836C2.03328 10.2922 2.42626 8.77296 3.18038 7.42586C3.9345 6.07876 5.02415 4.94955 6.34351 4.14789C7.66288 3.34623 9.16712 2.89935 10.7102 2.85066C12.2532 2.80197 13.7827 3.15311 15.1499 3.87" stroke="#232323" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square"/>
            </g>
        </g>
    </svg>
)
}