import React, {FC, useState} from "react";
import cn from "classnames";
import {FieldFormik} from "@src/components/common/FieldFormik/FieldFormik";
import {SelectCustom} from "@src/components/common/Select/SelectCustom";
import {Calendar} from "@src/components/AllSvg/Calendar";
import arrow from "@src/images/arrow.svg"
import style from '@src/pages/Tours/TourFormalization/components/steps/Step2/Step2.module.scss'
import {useFormikContext} from "formik";
import {useSelector} from "react-redux";
import {selectCitizenCategory, selectCityTour, selectGender, selectRoomTypes} from "@src/store/ducks/orders/selectors";
import {UserSvg} from "@src/components/AllSvg/UserSvg";

type TouristFormProps = {
    namePart?: any
    num: number,
    nameSelectArr?: any
}

export const TouristForm: FC<TouristFormProps> = ({namePart, num, nameSelectArr}) => {
    const [showForm, setShowForm] = useState(true)
    const {getFieldProps} = useFormikContext()


    const dataRoomTypesFromApi = useSelector(selectRoomTypes)
    const dataCitizenCategoryFRomApi = useSelector(selectCitizenCategory)
    const dataGenderFRomApi = useSelector(selectGender)
    const dataCityTours = useSelector(selectCityTour)


    let dataRoomTypes: any = []
    let dataCitizenCategory: any = []
    let dataGender: any = []
    let dataCity: any = []


    function dataPreparation() {
        dataCitizenCategory = Object.keys(dataCitizenCategoryFRomApi).map(key => {
            return {
                value: key,
                label: dataCitizenCategoryFRomApi[key]
            };
        });
        dataRoomTypes = dataRoomTypesFromApi.map(item => {
            return {
                value: item.id,
                label: item.title
            };
        });
        dataGender = Object.keys(dataGenderFRomApi).map(key => {
            return {
                value: key,
                label: dataGenderFRomApi[key]
            };
        });

        dataCity = dataCityTours?.filter(item => item[0] !== '1')
            .map(item => ({
                value: item[1],
                label: item[1],
            }));
    }
    dataPreparation()

    return (
        <div className={style.touristForm}>
            <div className={cn(style['touristForm-title'], showForm ? '' : style['svg-down'])}
                 onClick={() => setShowForm(!showForm)}>
                <UserSvg/>
                <span>Турист №{num} ({getFieldProps(`${nameSelectArr + '.citizenCategory'}`).value.value})</span>
                <div className={style['wrap-arrow']}>
                    <img src={arrow} alt={arrow}/>
                </div>
            </div>

            {showForm &&
                <div className={style['touristForm-block']}>
                    <FieldFormik name={`${namePart + '.fio'}`} label='ФИО на русском'
                                 placeholder='Пример: Иванов Иван Иванович'
                                 typeInput='input-usual'/>
                    <FieldFormik name={`${namePart + '.fioLat'}`} label='ФИО латиницей'
                                 placeholder='Пример: Ivanov Ivan' typeInput='input-usual'/>
                    <FieldFormik name={`${namePart + '.birthDate'}`} label='Дата рождения' placeholder='Выберите дату'
                                 svgComponent={Calendar} typeInput='input-usual' inputMode="numeric"
                                 mask={'99.99.9999'}/>
                    <FieldFormik name={`${namePart + '.passportSeriesAndNumber'}`} label='Серия и номер паспорта'
                                 placeholder='Пример: МР0000000' typeInput='input-usual'/>
                    <FieldFormik name={`${namePart + '.passportExpiryDate'}`} label='Дата окончания паспорта'
                                 placeholder='Выберите дату' svgComponent={Calendar} inputMode="numeric"
                                 typeInput='input-usual' mask={'99.99.9999'}/>
                    <FieldFormik name={`${namePart + '.phoneNumber'}`} label='Номер телефона туриста'
                                 placeholder='+375 (00) 000-00-00' typeInput='input-usual' inputMode="numeric"
                                 mask={'+375 (99) 9999999'}/>
                    <SelectCustom data={dataRoomTypes} num={num} name={`${nameSelectArr + '.roomType'}`}
                                  heading='Выберите из списка'
                                  placeholder="Выберите из списка" labelTitle='Категория номера в отеле'
                                  className={'select-full'}></SelectCustom>
                    <SelectCustom data={dataCitizenCategory} num={num} name={`${nameSelectArr + '.citizenCategory'}`}
                                  heading='Выберите категорию'
                                  placeholder="Выберите из списка"
                                  labelTitle='Категория гражданина (-ки)' className={'select-full'}></SelectCustom>
                    <SelectCustom data={dataGender} num={num} name={`${nameSelectArr + '.gender'}`}
                                  heading='Выберите из списка'
                                  placeholder="Выберите из списка" labelTitle='Пол туриста'
                                  className={'select-full'}></SelectCustom>
                    <SelectCustom className={'select-full'} num={num} data={dataCity? dataCity : []} name={`${nameSelectArr + '.fromCity'}`}
                                  heading='Выберите из списка'
                                  placeholder="Укажите город"  labelTitle='Город выезда'></SelectCustom>
                    <FieldFormik name={`${namePart + '.nationality'}`}
                                 placeholder="Укажите гражданство" label='Гражданство'
                                 typeInput='input-usual'></FieldFormik>
                    <FieldFormik name={`${namePart + '.hotel'}`}
                                 placeholder="Название отеля" label='Отель' typeInput='input-usual'></FieldFormik>
                </div>
            }
        </div>
    )
}
