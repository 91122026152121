import React from "react";


export const PasswordSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 36 36" fill="none">
            <path d="M21.7365 23.25H21.75M14.25 23.25H14.2635" stroke="#0062AA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.40171 28.267C6.73903 30.7725 8.81419 32.7352 11.3395 32.8513C13.4644 32.949 15.623 33 18 33C20.377 33 22.5355 32.949 24.6604 32.8513C27.1858 32.7352 29.261 30.7725 29.5983 28.267C29.8185 26.632 30 24.9564 30 23.25C30 21.5436 29.8185 19.868 29.5983 18.233C29.261 15.7275 27.1858 13.7647 24.6604 13.6486C22.5355 13.551 20.377 13.5 18 13.5C15.623 13.5 13.4644 13.551 11.3395 13.6486C8.81419 13.7647 6.73903 15.7275 6.40171 18.233C6.18157 19.868 6 21.5436 6 23.25C6 24.9564 6.18157 26.632 6.40171 28.267Z" stroke="#0062AA" strokeWidth="1.5"/>
            <path d="M11.25 13.5V9.75C11.25 6.02208 14.2721 3 18 3C21.728 3 24.75 6.02208 24.75 9.75V13.5" stroke="#0062AA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}