import React from "react";


export const Seat = () => {
return (
    <svg width="100%" height="100%" viewBox="0 0 13.0017 13.6648" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="Vector (Stroke)" d="M3.81152 6.99895C3.38037 6.97931 3 7.338 3 7.81734C3 8.29037 3.37793 8.66481 3.83325 8.66481L8.96484 8.66481C9.16333 8.66481 9.33325 8.50064 9.33325 8.28706C9.33325 8.15414 9.27075 8.08051 9.21265 8.05357C8.55054 7.74716 6.87842 7.13873 3.81152 6.99895L3.81152 6.99895ZM2 7.81734C2 6.80827 2.81689 5.95258 3.85693 5.99998C7.04175 6.14514 8.84277 6.78041 9.63281 7.14613L9.63281 7.14616C10.1025 7.36377 10.3333 7.83307 10.3333 8.28706C10.3333 9.04297 9.72559 9.66481 8.96484 9.66481L3.83325 9.66481C2.81592 9.66481 2 8.8327 2 7.81734Z" fill="#0062AA" fillOpacity="1.000000" fillRule="evenodd"/>
        <path id="Vector (Stroke)" d="M8.87671 1.51656C9.35376 0.211349 10.8152 -0.216614 12.0586 0.0994568L12.0586 0.0994568C12.7314 0.270462 13.1843 0.961044 12.9299 1.65897L12.9282 1.66316L12.9282 1.66316L10.3015 8.67354C10.2046 8.93213 9.9165 9.0632 9.65796 8.96632C9.39941 8.86943 9.26831 8.58125 9.36523 8.32266L11.9907 1.31506C12.0063 1.27107 12.0022 1.22688 11.9771 1.18365C11.9509 1.13838 11.8979 1.09038 11.8123 1.06865L8.87671 1.51656ZM11.8123 1.06865C10.8772 0.830948 10.0571 1.19984 9.81592 1.8598L9.81592 1.8598C9.41919 2.94534 9 4.64354 9 7.16476C9 7.44092 8.77612 7.66476 8.5 7.66476C8.22388 7.66476 8 7.44092 8 7.16476C8 4.52727 8.43945 2.71284 8.87671 1.51656L11.8123 1.06865Z" fill="#0062AA" fillOpacity="1.000000" fillRule="evenodd"/>
        <path id="Vector (Stroke)" d="M8.69043 4.0096C8.69067 4.00963 8.69067 4.00966 8.5 4.47188L8.69043 4.0096C8.9458 4.11488 9.06763 4.40726 8.96216 4.66254C8.85693 4.91779 8.5647 5.03937 8.30933 4.93414L8.30884 4.93385L8.30493 4.93231L8.28809 4.92551C8.27295 4.9194 8.24976 4.91023 8.21997 4.89856C8.15991 4.8752 8.07251 4.84186 7.96436 4.80286C7.7478 4.72459 7.4519 4.62479 7.13208 4.53693C6.81006 4.44841 6.47705 4.37573 6.18311 4.34625C5.87207 4.31509 5.67651 4.34032 5.58228 4.38193C5.41333 4.45665 5.26685 4.58141 5.1626 4.74303C5.10449 4.83318 5.05518 4.97208 5.02808 5.26027C5.00049 5.55339 5 5.93828 5 6.49814C5 6.77428 4.77612 6.99814 4.5 6.99814C4.22388 6.99814 4 6.77428 4 6.49814L4 6.47336C4 5.94431 4 5.51213 4.03247 5.16656C4.06592 4.8107 4.13794 4.48662 4.32227 4.20093C4.53076 3.87785 4.82764 3.62219 5.17822 3.46725C5.51685 3.31761 5.92749 3.31564 6.28271 3.35124C6.65479 3.38852 7.04761 3.47656 7.39722 3.57268C7.74927 3.66946 8.07104 3.77818 8.30396 3.8623C8.4209 3.9045 8.51636 3.94081 8.58301 3.9668C8.61646 3.9798 8.64258 3.99023 8.66089 3.99756L8.68213 4.00615L8.68799 4.00856L8.69043 4.0096Z" fill="#0062AA" fillOpacity="1.000000" fillRule="evenodd"/>
        <path id="Vector (Stroke)" d="M2.5 7.33145C2.77612 7.33145 3 7.55531 3 7.83145L3 9.8483C3 10.1446 3 10.392 2.98608 10.595C2.97168 10.8064 2.94067 11.0061 2.86035 11.1997C2.67432 11.6488 2.31738 12.0058 1.86816 12.1919C1.6748 12.2721 1.47485 12.3032 1.26367 12.3176C1.06055 12.3315 0.813232 12.3315 0.516846 12.3315L0.5 12.3315C0.223877 12.3315 0 12.1076 0 11.8315C0 11.5553 0.223877 11.3315 0.5 11.3315C0.817383 11.3315 1.03052 11.3312 1.19556 11.3199C1.35596 11.309 1.43408 11.2894 1.4856 11.2681L2.5 7.33145ZM2.5 7.33145C2.22388 7.33145 2 7.55531 2 7.83145L2.5 7.33145ZM2 7.83145L2 9.83145L2 7.83145ZM2 9.83145C2 10.1489 1.99976 10.362 1.98853 10.527L2 9.83145ZM1.98853 10.527C1.97754 10.6874 1.95776 10.7655 1.93652 10.817L1.98853 10.527ZM1.93652 10.817C1.85205 11.0212 1.6897 11.1835 1.4856 11.2681L1.93652 10.817Z" fill="#0062AA" fillOpacity="1.000000" fillRule="evenodd"/>
        <path id="Vector (Stroke)" d="M6.5 8.66479C6.77612 8.66479 7 8.88866 7 9.16479L7 13.1648C7 13.4409 6.77612 13.6648 6.5 13.6648C6.22388 13.6648 6 13.4409 6 13.1648L6 9.16479C6 8.88866 6.22388 8.66479 6.5 8.66479Z" fill="#0062AA" fillOpacity="1.000000" fillRule="evenodd"/>
        <path id="Vector (Stroke)" d="M4.66675 13.1648C4.66675 12.8887 4.89062 12.6648 5.16675 12.6648L7.8335 12.6648C8.10962 12.6648 8.3335 12.8887 8.3335 13.1648C8.3335 13.4409 8.10962 13.6648 7.8335 13.6648L5.16675 13.6648C4.89062 13.6648 4.66675 13.4409 4.66675 13.1648Z" fill="#0062AA" fillOpacity="1.000000" fillRule="evenodd"/>
    </svg>
)
}