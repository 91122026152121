import React, {FC} from 'react'
import cn from "classnames";

import {Logo} from "@src/components/AllSvg/Logo";

import style from '@src/components/TitleLogin/TitleLogin.module.scss'


type TitleProps = {
    title?: string,
    className?: string

}

export const TitleLogin: FC<TitleProps> = ({title, className}) => {
    return (
        <div className={cn(style.title, className)}>
            <Logo/>
            {title &&
                <h1>
                    {title}
                </h1>}
        </div>
    )
}

