import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useField, useFormikContext} from 'formik'
import cn from 'classnames'

import style from '@src/components/common/FieldFormik/FieldFormik.module.scss'
import eye from "@src/images/eye.svg";
import {Calendar} from "@src/components/AllSvg/Calendar";
import InputMask from 'react-input-mask';


type FieldFormikProps = {
    name: string
    type?: string
    placeholder?: any
    label?: string
    disabled?: boolean
    className?: string
    mask?: string | any
    autocomplete?: 'off' | 'on',
    typeInput?: string,
    text?: any,
    svgComponent?: React.ComponentType<unknown>,
    pattern?: string,
    valueT?: string
    svg?: boolean
    inputMode?: any
}

export const FieldFormik: FC<FieldFormikProps> = ({
                                                      autocomplete = 'on',
                                                      disabled,
                                                      className,
                                                      name,
                                                      type = 'text',
                                                      label,
                                                      placeholder,
                                                      typeInput,
                                                      text,
                                                      svgComponent: SvgComponent,
                                                      mask, svg,
                                                      inputMode

                                                  }) => {
    const {setFieldValue, getFieldProps} = useFormikContext()
    const [field, meta] = useField(name)
    const [isFocused, setIsFocused] = useState<boolean>(false)
    const isError = useMemo(() => !isFocused && meta.error && meta.touched, [isFocused, meta.error, meta.touched])
    const [widthSpan, setWidthSpan] = useState<number | null>(null);
    const refSpan = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [typeShowInput, setTypeShowInput] = useState<boolean>(true)
    const finalPriceByn = getFieldProps('data.finalPriceByn').value

    //отслеживание изменения ширины span внутри inputSelect
    useEffect(() => {
        function handleResize() {
            if (refSpan.current) {
                setWidthSpan(refSpan.current.offsetWidth);
            }
        }

        handleResize();

        if (inputRef.current) {
            inputRef.current.style.paddingLeft = `calc(3.3rem + ${widthSpan}px)`

            /*field.value !== '' ? inputRef.current.style.paddingLeft = paddingLeftDesctop : inputRef.current.style.paddingLeft = '2.5rem';*/
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [widthSpan]);

    //изменение внутри инпут
    //при focus
    const handleFocus = useCallback(() => {
        setIsFocused(true)
    }, [])

    //при blur
    const handleBlur = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        setIsFocused(false)
        field.onBlur(e)
    }, [field])

    const handleChange1 = (event: any) => {
        const { value } = event.target;
        const isValidValue = value <= +finalPriceByn && value >= 0;

        if (isValidValue) {
            if (value.length > 1 && value[0] !== '0') {
                setFieldValue('data.finalFirstPartPriceByn', value);
            } else if (value.length <= 1) {
                setFieldValue('data.finalFirstPartPriceByn', value);
            }
        }
    };

    return (
        <div className={cn(style.form, className)}>
            {typeInput === 'input-usual' && <label>
                {label &&
                    <div className={style.label}>{label}</div>}
                <div className={style.inputBlock}>
                    {SvgComponent && <SvgComponent/>}
                    <InputMask
                        {...field}
                        mask={mask}
                        className={cn(style.input, isError && style.input_error, className, SvgComponent ? style.inputBlockSvg : '', ` ${field.value ? style['input-fill'] : ''}`)}
                        type={type}
                        placeholder={placeholder}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        disabled={disabled}
                        autoComplete={autocomplete}
                        inputMode={inputMode}
                    />
                </div>
                {isError && <div className={style.error}>{meta.error}</div>}
            </label>}
            {typeInput === 'input-currency' &&
                <div className={cn(style.inputBlock, style.selectInputBlock)}>
                    <label>
                        {label &&
                            <div className={style.label}>{label}</div>}
                        <div className={style.inputBlock}>
                            {SvgComponent && <SvgComponent/>}
                            <input
                                {...field}
                                className={cn(style.input, style['input-date'], ` ${field.value ? style['input-fill'] : ''}`, className)}
                                type={type}
                                placeholder={placeholder}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                disabled={disabled}
                                autoComplete={autocomplete}
                                inputMode={inputMode}
                                onChange={handleChange1}
                                value={field.value}
                            />
                        </div>
                        {isError && <div className={style.error}>{meta.error}</div>}
                    </label>
                </div>}
            {typeInput === 'input-select' &&
                <div className={cn(style.inputBlock, style.selectInputBlock)}>
                    <span className={field.value ? style.selectSpanActive : style.selectSpan}
                          ref={refSpan}>{field.value ? placeholder + ':' : placeholder}</span>
                    <input
                        ref={inputRef}
                        {...field}
                        className={`${style.input} ${field.value !== '' ? style['input-fill'] : ''}`}
                        type={"number"}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        disabled={disabled}
                        autoComplete={autocomplete}
                        maxLength={4}
                    />
                </div>}
            {typeInput === 'input-password' &&
                <label>
                    {label && <div className={style.label}>{label}</div>}
                    <div className={style['hide-password-block']}>
                        <input
                            {...field}
                            className={cn(style.input, isError && style.input_error, className)}
                            type={typeShowInput ? 'password' : 'text'}
                            placeholder={placeholder ? placeholder : null}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            disabled={disabled}
                            autoComplete={autocomplete}
                        />
                        <button className={style['hide-password']}>
                            <img src={eye} alt="eye" onClick={() => setTypeShowInput(!typeShowInput)}/>
                        </button>
                    </div>
                    {isError && <div className={style.error}>{meta.error}</div>}
                </label>}
            {typeInput === 'input-date' &&
                <div className={cn(style.inputBlock, style.selectInputBlock)}>
                    <label>
                        {label && <div className={style.label}>{label}</div>}
                        <div className={style['input-date-container']}>
                            {svg && <Calendar/>}
                            <input
                                className={cn(style.input, style['input-fill'], style['input-date'], className)}
                                type={"text"}
                                value={text}
                                readOnly
                            />
                        </div>
                    </label>
                </div>}
        </div>
    )
}
