import React, {useEffect} from "react";
import cn from 'classnames'
import loginBg from '@src/images/login-bg.jpg'
import {TitleLogin} from "@src/components/TitleLogin/TitleLogin";
import {LoginForm} from "@src/pages/Login/LoginForm";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectIsAuth} from "@src/store/ducks/auth/selectors";
import style from '@src/pages/Login/Login.module.scss'


export const Login = () => {
    let navigate = useNavigate();
    const isAuth = useSelector(selectIsAuth)
    useEffect(() => {
        if (isAuth){
            return navigate("/tours");
        }
    },[isAuth]);

  return (
    <div className={cn(style.login)} >
        <div className={cn(style['login-left'])}>
          <img
              src={loginBg}
              alt="loginBg"
          />
        </div>
        <div className={cn(style['login-right'])}>
            <div className={cn(style['login-right-body'])}>
                <TitleLogin title={'Вход для партнеров'} className={style.titleLogin}/>
                <LoginForm isLoading={false}/>
            </div>
        </div>
    </div>
  )
}
