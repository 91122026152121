import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export const initialState = {
    isInitialized: false,
    isAuth: false,
    isLoading: false,
    backError: null as null | object | string | any,
    userData: {
        login: '',
        password: '',
    },
}
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
/*        setIsInitialized(state) {
            state.isInitialized = true
        },*/
        setIsAuth(state, {payload}: PayloadAction<boolean>) {
            state.isAuth = payload
        },
        setIsLoading(state, {payload}: PayloadAction<boolean>) {
            state.isLoading = payload
        },
        setBackError(state, { payload }: PayloadAction<object | null | string>) {
            state.backError = payload
        },
        logoutAC() {
        },
        setUserEmail(state, {payload}: PayloadAction<string>) {
            state.userData.login = payload
        },

    },
})
export type AuthStateType = typeof initialState
export const {
    setIsAuth,
    setIsLoading,
    logoutAC,
    setBackError,
    setUserEmail
} = authSlice.actions
export const authReducer = authSlice.reducer
