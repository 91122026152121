import {ChangeEvent, FC, ReactNode} from 'react'
import cn from 'classnames'

import style from '@src/components/common/CheckBoxFormik/CheckBoxFormik.module.scss'
import {useFormikContext} from "formik";

type CheckBoxFormikProps = {
    name: string
    type?: string
    label?: string | ReactNode
    className?: string
    value?: string | number
    onChange?: (value?: any) => void
    checked?: boolean
    randomFilling?: any
}

export const CheckBoxFormik: FC<CheckBoxFormikProps> = ({
                                                            className,
                                                            name,
                                                            type = 'checkbox',
                                                            label,
                                                            value,
                                                            checked,
                                                        }) => {
    const {setFieldValue} = useFormikContext();
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setFieldValue(name, true);
        } else {
            setFieldValue(name, false);
        }
    };

    return (
        <div className={cn(style.box, className)}>
            <label className={style.custom_checkbox}>
                <input
                    className={style.custom_checkbox}
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    checked={checked}
                />
                <span>
        {' '}
                    {label}
      </span>
            </label>
        </div>
    )
}
