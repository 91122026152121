import {useDispatch, useSelector} from "react-redux";
import style from "@src/pages/History/TableHistoryElem/TableHistoryElem.module.scss";
import React, {FC, useState} from "react";
import {AppDispatch} from "@src/store/store";
import cn from "classnames";
import {OrdersHistoryElemType} from "@src/store/ducks/orders/types";
import {selectOrderStatuses} from "@src/store/ducks/orders/selectors";
import {Button} from "@src/components/Button/Button";
import {Download} from "@src/components/AllSvg/Download";
import {HistoryDetails} from "@src/pages/History/HistoryDetails/HistoryDetails";
import {getPdfActAsinc, getPdfReportAsinc} from "@src/store/ducks/orders/thunks";
import {Calendar} from "@src/components/AllSvg/Calendar";


type TableHistoryElemProps = {
    item: OrdersHistoryElemType
};

export const TableHistoryElem: FC<TableHistoryElemProps> = ({item}) => {
    const dispatch = useDispatch<AppDispatch>()
    const orderStatuses = useSelector(selectOrderStatuses)
    const [showElem, setShowElem] = useState(false)

    let status = null
    if (orderStatuses && item?.status) {
        status = orderStatuses[item.status]
        console.log(status)
        console.log(item.status)
    }

    let tour = {
        id: item.id,
        status: status,
        number: item?.number,
        startDate: item?.startDate,
        endDate: item?.endDate,
        title: item?.tour.title
    }

    return (
        <div className={style['table-history-elem-wrapper']}>
            <div className={style['table-history-elem']}>
                <div className={cn(style['TableElem-block'], style.status)}>
                    <label>Статус заказа</label>
                    <div className={cn(style['TableElem-flex'])}>
                        <div className={cn(style.statusBtn, style[`color${item?.status}`])}>
                            {tour.status}
                        </div>
                    </div>
                </div>
                <div className={cn(style['TableElem-block'], style.ordersNumber)}>
                    <label>Номер заказа</label>
                    <div className={cn(style['TableElem-flex'])}>{tour.number}</div>
                </div>
                <div className={cn(style['TableElem-block'], style.title)}>
                    <label>Название тура</label>
                    <a className={cn(style['TableElem-flex'])} href={'#'}>{tour.title}</a>
                </div>
                <div className={cn(style['TableElem-block'], style['custom-text'], style.date)}>
                    <label>Дата проведения тура</label>
                    <div className={style['TableElem-flex']}>
                        <Calendar/>
                        {tour.startDate} - {tour.endDate}
                    </div>
                </div>
                <div onClick={() => {
                    setShowElem(!showElem)
                }} className={cn(style['TableElem-block'], style.details)}>
                    <label>Маршрут тура</label>
                    <div className={style['TableElem-flex']}>
                        <a href={'#'} className={style.link}>
                            Детали заказа
                        </a>
                    </div>
                </div>
                <div className={cn(style['TableElem-block'], style.downloadAct)}>
                    <label>Скачать акт</label>
                    {/* Статусы Принят, Оплачен, Не оплачен - 4, 7, 8*/}
                    {['4', '7', '8'].includes(item?.status) && (
                        <div className={style['TableElem-flex']}>
                            <Button onClickFunc={() => dispatch(getPdfReportAsinc(tour.id))} svgComponent={Download}
                                    className={style.downloadBtn}>Скачать акт</Button>
                        </div>
                    )}
                </div>
                <div className={cn(style['TableElem-block'], style.downloadCount)}>
                    <label>Скачать счет</label>
                    {/* Статусы Принят, Оплачен, Не оплачен - 4, 7, 8*/}
                    {['4', '7', '8'].includes(item?.status) && (
                        <div className={style['TableElem-flex']}>
                            <Button onClickFunc={() => dispatch(getPdfActAsinc(tour.id))} svgComponent={Download}
                                    className={style.downloadBtn}>Скачать счет</Button>
                        </div>
                    )}
                </div>
            </div>
            {showElem && <HistoryDetails data={item} setShowElem={setShowElem}/>}
        </div>
    )
}


