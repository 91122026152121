import React, {FC} from 'react'

import style from '@src/components/Title/Title.module.scss'

type TitleProps = {
    title: string,
    svgComponent: React.ComponentType<unknown>,
    ammountTours?: number
}

export const Title: FC<TitleProps> = ({ title , svgComponent: SvgComponent, ammountTours}) => {
    return (
        <div className={style.title}>
            <SvgComponent />
            <h2>
                {title} {ammountTours && <span className={style.ammountTours}>({ammountTours})</span>}
            </h2>
        </div>
    )
}

