import React from "react";


export const Place = () => {
    return (
        <svg width="48.000000" height="36.000000" viewBox="0 0 48 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs/>
            <rect id="Rectangle 48" x="38.500000" y="2.500000" rx="2.000000" width="9.000000" height="31.000000" fill="#0062AA" fillOpacity="1.000000"/>
            <rect id="Rectangle 48" x="38.500000" y="2.500000" rx="2.000000" width="9.000000" height="31.000000" stroke="#232323" strokeOpacity="1.000000" strokeWidth="1.000000"/>
            <rect id="Rectangle 47" x="31.500000" y="1.500000" rx="2.000000" width="9.000000" height="33.000000" fill="#0062AA" fillOpacity="1.000000"/>
            <rect id="Rectangle 47" x="31.500000" y="1.500000" rx="2.000000" width="9.000000" height="33.000000" stroke="#232323" strokeOpacity="1.000000" strokeWidth="1.000000"/>
            <rect id="Rectangle 46" x="0.500000" y="0.500000" rx="2.000000" width="33.000000" height="35.000000" fill="#0062AA" fillOpacity="1.000000"/>
            <rect id="Rectangle 46" x="0.500000" y="0.500000" rx="2.000000" width="33.000000" height="35.000000" stroke="#232323" strokeOpacity="1.000000" strokeWidth="1.000000"/>
        </svg>
    )
}