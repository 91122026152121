import {api} from '@src/api/api'
import {TripsDataType, TripsResultType} from "@src/store/ducks/trips/types";


export const TripsApi = {
  getTripsData() {
    return api.get<TripsDataType>('/v1/trips/filters')
  },
  getTripsResult(data= {}) {
    return api.get<TripsResultType>('/v1/trips/list', data )
  },
}
